import React from 'react';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import FailureInfo from './FailureInfo';
import FailureStepperContainer from './FailureStepperContainer';
import RepairInfo from './RepairInfo';
import { useTranslation } from 'react-i18next';
import {
  Failure,
  EquipmentPart,
  FailureActionReason,
  User,
  FailureStatus,
} from '@factorise/api/types';
import { SimpleDialog } from '@factorise/components';

export interface FailureResolvingDialogProps {
  loading: boolean;
  error: boolean;
  open: boolean;
  onClose: () => void;
  failure?: Failure;
  statuses?: FailureStatus[];
  reasons?: FailureActionReason[];
  parts?: EquipmentPart[];
  permitted?: boolean;
  accessesLoading?: boolean;
  onActionPerformed?: () => void;
  user?: User;
}

const FailureResolvingDialog: React.FunctionComponent<
  FailureResolvingDialogProps
> = ({
  loading,
  error,
  open,
  onClose,
  failure,
  reasons,
  statuses,
  parts,
  onActionPerformed,
  user,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const actions = (
      <Button autoFocus onClick={onClose} color="secondary">
        {t('general.buttons.cancel')}
      </Button>
    );

    return (
      <SimpleDialog
        fullScreen={fullScreen}
        open={open}
        showLoader={loading}
        title={t('modals.failureResolving.title')}
        actions={actions}
      >
        <FailureInfo failure={failure} />
        <RepairInfo user={user} />
        <FailureStepperContainer
          failure={failure}
          reasons={reasons}
          parts={parts}
          statuses={statuses}
          onActionPerformed={onActionPerformed}
        />
      </SimpleDialog>
    );
  };

export default FailureResolvingDialog;
