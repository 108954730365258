import React, { forwardRef } from 'react';
import {
  useLocation,
  matchPath,
  NavLink as RouterLink,
} from 'react-router-dom';
import { Button, IconButton, Tooltip } from '@mui/material';

interface NavItemProps {
  title: string;
  href: string;
  icon: React.ReactNode;
  compactSize: boolean;
  onClick?: () => void;
}

const styles = {
  button: {
    fontWeight: 'medium',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    color: 'text.secondary',
    '& svg': {
      mr: 0,
    },
  },
} as const;

const CustomRouterLink = forwardRef((props: any, ref: any) => {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const NavItem: React.FC<NavItemProps> = ({
  href,
  icon,
  title,
  compactSize,
  onClick,
}) => {
  const location = useLocation();
  const active = href
    ? !!matchPath({ path: href, end: false }, location.pathname)
    : false;

  const buttonStyles = {
    ...styles.button,
    ...(active ? { color: 'primary.main' } : {}),
  };

  return (
    <>
      {!compactSize ? (
        <Button
          onClick={onClick}
          size="large"
          sx={buttonStyles}
          component={CustomRouterLink}
          to={href}
          startIcon={icon}
        >
          {!compactSize && title}
        </Button>
      ) : (
        <Tooltip placement="right" title={title}>
          <div>
            <IconButton
              sx={buttonStyles}
              component={CustomRouterLink}
              to={href}
              size="large"
              children={icon}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default NavItem;
