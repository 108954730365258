import React from 'react';
import { SelectWithLabel } from '@factorise/components';
import { FailureReasons } from '@factorise/api/types';
import { useTranslation } from 'react-i18next';

interface ReasonInputProps {
  label: string;
  required: boolean;
  name: string;
  onChange?: (value: any) => void;
  value: any;
  equipmentId?: number;
  reasons: FailureReasons[]
  loading: boolean
}

const ReasonInput: React.FunctionComponent<ReasonInputProps> = ({
  label,
  required,
  name,
  onChange,
  value,
  equipmentId,
  loading,
  reasons
}) => {
  const { t } = useTranslation();

  // const reasonsQuery = useQuery<FailureReasons[]>({
  //   method: 'GET',
  //   endpoint: `/api/failureReasons?equipmentId=${equipmentId || 0}`,
  // });

  // if (reasonsQuery.error) {
  //   enqueueSnackbar(t('modals.failureResolving.fetchingDataError'), {
  //     variant: 'error',
  //     preventDuplicate: true,
  //   });
  // }

  // const reasonsData = reasonsQuery.response?.payload;

  return (
    <SelectWithLabel
      loading={loading}
      disabled={!equipmentId}
      label={label}
      options={
        reasons?.map((reason: FailureReasons) => ({
          value: reason.id,
          label: reason.name,
          parent: reason.type?.name,
        })) || []
      }
      helperText={
        !equipmentId ? t('modals.failureResolving.selectMachineFirstInfo') : ''
      }
      required={required}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
};

export default ReasonInput;
