import React from 'react';
import { Page } from '@factorise/components';
import UsersTable from './UsersTable';
import UsersToolbar from './UsersToolbar';
import { useTranslation } from 'react-i18next';
import { Role, User } from '@factorise/api/types';
import { SortingSettings } from 'types';
import { Stack, Box } from '@mui/material';

interface UsersViewPageProps {
  loading: boolean;
  error: boolean;
  permitted: boolean;
  users: User[];
  sorting: {
    sorting: SortingSettings;
    onSort: (column: string) => void;
  };
  pagination: {
    page: number;
    onPageChange: (page: number) => void;
    onLimitChange: (limit: number) => void;
    rowsTotal: number;
    rowsPerPage: number;
  };
  selection: {
    selection: number[];
    onRecordSelect: (id: number) => void;
    onAllRecordsSelect: () => void;
  };
  roles?: Role[];
  reloadUsers: () => void
}

const UsersViewPage: React.FC<UsersViewPageProps> = ({
  loading,
  error,
  permitted,
  users,
  sorting,
  pagination,
  selection,
  roles,
  reloadUsers,
}) => {
  const { t } = useTranslation();

  return (
    <Page showLoader={loading} permitted={permitted} title={t('menu.users')}>
      <Stack sx={{ width: '100%', height: '100%' }} direction="column">
        <UsersToolbar roles={roles} reloadUsers={reloadUsers} users={users} pagination={pagination} selectedUserIds={selection.selection} />
        <Box sx={{ flexGrow: 1 }}>
          <UsersTable selection={selection} sorting={sorting} users={users} />
        </Box>
      </Stack>
    </Page>
  );
};

export default UsersViewPage;
