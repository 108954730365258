import React from 'react';
import {
  Grid,
  TextField,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Formik, FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { SimpleDialog } from '@factorise/components';
import * as Yup from 'yup';

const styles = {
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    minWidth: '320px',
  },
  fieldsSection: {
    pt: 3,
  },
  confirmMPassword: {
    ml: 2,
  },
} as const;
export interface EquipmentForm {
  name: string
}

export interface AddEquipmentProps {
  onClose: () => void;
  onSubmit: (
    data: EquipmentForm,
    onResetForm: () => void
  ) => void;
  open: boolean;
  levelTag: "WORK_CENTER" | "MACHINE"
}

const AddEquipment: React.FunctionComponent<AddEquipmentProps> = ({
  onClose,
  onSubmit,
  open,
  levelTag
}) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const { t } = useTranslation();

  const initialFormValues = {
    name: '',
  };

  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
  });

  const onFormSubmit = (values: any, helpers: any) => {
    const { resetForm } = helpers;

    onSubmit(values, resetForm);
  };

  const title = t(levelTag === 'WORK_CENTER' ? 'modals.addingEquipment.addingStationModalTitle' : "modals.addingEquipment.addingEquipmentModalTitle")
  const field = t(levelTag === 'WORK_CENTER' ? 'modals.addingEquipment.stationName' : "modals.addingEquipment.equipmentName")

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialFormValues}
      validationSchema={formValidationSchema}
      onSubmit={onFormSubmit}
    >
      {({
        submitForm,
        isSubmitting,
        touched,
        errors,
      }) => {
        return (
          <SimpleDialog
            fullScreen={fullScreen}
            open={open}
            title={title}
            actions={
              <>
                <Button autoFocus onClick={onClose} color="secondary">
                  {t('general.buttons.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {t('general.buttons.add')}
                </Button>
              </>
            }
          >
            <Grid
              container
              sx={styles.formContainer}
              direction="column"
              spacing={3}
            >
              <Grid item md={12} xs={12}>
                <FastField
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                  fullWidth
                  label={field}
                  required
                  name="name"
                  as={TextField}
                />
              </Grid>
            </Grid>
          </SimpleDialog>
        )
      }}
    </Formik>
  );
};

export default AddEquipment;
