import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'layouts/Main/Main';
import MinimalLayout from 'layouts/Minimal/Minimal';
import {
  LoginView,
  NotFoundView,
  RegisterView,
  AccessForbiddenView,
  WelcomeView,
  ConnectionErrorView,
  UserSettingsView,
  UsersView
} from 'views';
import NavigateToDefault from 'routes/NavigateToDefault'
import { viewsList } from 'views/viewsList'

const menuViews = viewsList.map(
  (view) => ({
    path: view.path,
    element: view.element
  })
)

const routes = (isLoggedIn: boolean) => [
  {
    path: 'app',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'welcome', element: <WelcomeView /> },
      { path: 'settings', element: <UserSettingsView /> },
      { path: 'users', element: <UsersView /> },
      ...menuViews,
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '/',
    element: <MinimalLayout />,
    children: [
      { path: 'login', element: !isLoggedIn ? <LoginView /> : <NavigateToDefault /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: 'noAccess', element: <AccessForbiddenView /> },
      { path: 'connectionError', element: <ConnectionErrorView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
];

export default routes;
