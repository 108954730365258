import { useEffect, useContext } from "react";
import AppLoading from "./AppLoading";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const LogOutView = () => {
  const { logOut, user } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    async function logUserOut() {
      await logOut()
      navigate('/login', { replace: true });
    }

    enqueueSnackbar(t('layouts.sessionExpired'), {
      variant: 'error',
      preventDuplicate: true,
    });
    setTimeout(() => user && logUserOut(), 1000);

  }, [logOut, navigate, enqueueSnackbar, t, user])

  return (
    <AppLoading />
  );
};

export default LogOutView;
