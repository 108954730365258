import {
  useMutation as useFetchingMutation,
  Action,
  QueryResponse,
} from 'react-fetching-library';

const useQuery = <T>(
  actionCreator: (action: T) => Action,
): {
  loading: boolean;
  response?: QueryResponse<T>;
  error?: any;
  mutate: (action: T) => Promise<QueryResponse<T>>;
  reset: () => void;
} => {
  const { status, error, payload, loading, headers, mutate, reset } =
    useFetchingMutation<T>(actionCreator);

  const response: QueryResponse<T> = {
    status,
    error,
    payload,
    headers,
  };

  return { loading, error, response, mutate, reset };
};
export default useQuery;
