import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useRoutes } from 'react-router-dom';
import routes from 'routes';
import theme from './theme';
import { AuthContext } from 'contexts/AuthContext';
import { QueryErrorBoundary } from '@factorise/api';
import LogOutView from './views/info/LogOutView'

const App: React.FunctionComponent = () => {
  const { user } = useContext(AuthContext)
  const routing = useRoutes(routes(!!user));

  return <>
    <QueryErrorBoundary
      statuses={[401]}
      fallback={() => {
        return (
          <LogOutView />
        )
      }}
    >
      <ThemeProvider theme={theme}>
        {routing}
      </ThemeProvider>
    </QueryErrorBoundary>
  </>;
};

export default App;
