import React from 'react';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    display: 'flex',
    m: 2,
  },
  spacer: {
    flexGrow: 1,
  },
};

export interface FiltersFooterProps {
  onApply: () => void;
  onClear: () => void;
  applyFilterDisabled?: boolean;
}

const FiltersFooter: React.FC<FiltersFooterProps> = ({
  onApply,
  onClear,
  applyFilterDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <Button variant="text" onClick={onClear}>
        {t('reports.finishedGoods.clearFilters')}
      </Button>
      <Box sx={styles.spacer} />
      <Button disabled={applyFilterDisabled} onClick={onApply} variant="outlined" color="primary">
        {t('reports.finishedGoods.applyFilters')}
      </Button>
    </Box>
  );
};

export default FiltersFooter;
