import React from 'react';
import FailureHistoryRow from './FailureHistoryRow';
import { FailureAction } from '@factorise/api/types';
import { FailureActions, useQuery } from '@factorise/api';

export interface FailureHistoryProps {
  failureId: number;
  opened: boolean;
  failureCreatedAt: Date;
}

const FailureHistory: React.FunctionComponent<FailureHistoryProps> = ({
  failureId,
  opened,
  failureCreatedAt,
}) => {
  const actionsQuery = useQuery<FailureAction[]>(FailureActions.getFailureActions({ id: failureId }));
  const actionData = actionsQuery.response?.payload;

  return (
    <FailureHistoryRow
      loading={actionsQuery.loading}
      error={actionsQuery.error}
      opened={opened}
      failureActionHistory={actionData}
      failureCreatedAt={failureCreatedAt}
    />
  );
};

export default FailureHistory;
