import React from 'react';
import PasswordForm from './PasswordForm';
import { useMutation } from 'react-fetching-library';
import { addUserNotification, UserNotification } from 'utils/UserNotifications';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { clearUserNotifications } from 'utils/UserNotifications';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { UserActions } from '@factorise/api';

export interface PasswordCardProps {
  userId: number
}

const PasswordCard: React.FC<PasswordCardProps> = ({ userId }) => {
  const { mutate: changePasswordApi } = useMutation(UserActions.changePassword);
  const { logOut } = useContext(AuthContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const logOutHandler = async () => {
    closeSnackbar();
    clearUserNotifications();
    await logOut();
  };

  const onActionError = () => {
    const errorMessage = t('userSettings.passwordChangeError');
    const notification: UserNotification = {
      severity: 'error',
      message: errorMessage,
      screenName: 'userSettings',
      date: new Date(),
    };
    addUserNotification(notification);
    enqueueSnackbar(errorMessage, {
      variant: 'error',
      disableWindowBlurListener: true,
    });
  };

  const changePassword = async (
    values: any,
    resetForm: () => void,
    showWrongPasswordError: () => void
  ) => {
    const queryProps = {
      currentPassword: values.current,
      newPassword: values.new,
      userId,
    };

    const { error, payload } = await changePasswordApi(queryProps)

    if (error) {
      switch (payload.code) {
        case 'INVALID_CREDENTIALS':
          showWrongPasswordError();
          return;
        default:
          onActionError();
          return;
      }
    }
    enqueueSnackbar(t('userSettings.passwordChangedSuccessfully'), {
      variant: 'success',
      disableWindowBlurListener: true,
    });
    resetForm();
    logOutHandler();
  };

  return (
    <PasswordForm onSubmit={changePassword} />
  );
};

export default PasswordCard;
