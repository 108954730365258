import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from './translations/i18-translations-en.json';
import translationsPl from './translations/i18-translations-pl.json';
import moment from 'moment';
import 'moment/locale/pl';

const resources = {
  en: {
    translation: translationsEn,
  },
  pl: {
    translation: translationsPl,
  },
};

moment.locale('pl');
i18n.use(initReactI18next).init({
  resources,
  lng: 'pl',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
