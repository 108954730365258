import React, { useState, useEffect } from 'react';
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { Failure } from '@factorise/api/types';
import getFormattedDate from 'utils/getFormattedDate';
import FailureResolving from '../../resolving';
import getCssContrastColoring from 'utils/getCssContrastColoring';
import { useTranslation } from 'react-i18next';
import FailureActionButton from '../FailureActionButton';
import FailureHistory from '../FailureHistory';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MobileTableBody from './MobileTableBody';

const styles = {
  dot: {
    mr: 1,
    height: '10px',
    width: '10px',
    borderRadius: '50%',
  },
  hidden: {
    display: { xs: 'none', sm: 'table-cell' },
  },
} as const;

type FailuresTableProps = {
  failures: Failure[];
  performedAction?: {
    failureId?: number;
    changeFailureId: (value?: number | undefined) => void;
    onActionPerformed: () => void;
  };
  failureDetails?: {
    failureId?: number;
    changeFailureId: (value?: number | undefined) => void;
  };
};

const FailuresTable: React.FunctionComponent<FailuresTableProps> = ({
  failures,
  performedAction,
  failureDetails,
}) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const closeFailureResolving = () => {
    performedAction?.changeFailureId?.(undefined);
  };
  const openFailureResolving = (id: number | undefined) => {
    performedAction?.changeFailureId?.(id);
  };
  const failureId = failureDetails?.failureId;

  const getDesktopContent = (
    id: number,
    availableHistory: boolean,
    equipment: string,
    reason: string,
    user: string,
    priority: React.ReactNode,
    date: string,
    status: React.ReactNode
  ) => {
    return !isMobile ? (
      <>
        <TableCell padding="checkbox">
          {availableHistory && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => failureDetails?.changeFailureId(id)}
            >
              {failureId === id ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{equipment}</TableCell>
        <TableCell>{reason}</TableCell>
        <TableCell>{user}</TableCell>
        <TableCell>{priority}</TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{status}</TableCell>
      </>
    ) : (
      <MobileTableBody
        equipment={equipment}
        reason={reason}
        date={date}
        status={status}
        priority={priority}
      />
    );
  };

  const getTableContent = () => {
    return (failures || []).map(
      ({ id, user, reason, priority, status, equipment, createdAt }) => {
        const hasHistory = status.tag !== 'NEW';
        const equipmentName = equipment?.name;
        const reasonName = reason?.name;
        const userName = `${user.firstName} ${user.lastName}`;
        const priorityElement = (
          <Box display="flex" alignItems={'center'}>
            <Box style={{ background: priority.color }} sx={styles.dot} />
            <Typography> {priority.name} </Typography>
          </Box>
        );
        const statusElement = (
          <>
            <Chip
              style={getCssContrastColoring(status.color || '#3f51b5')}
              label={status?.name || 'New'}
              size="small"
            />
          </>
        );

        return (
          <React.Fragment key={`row_${id}`}>
            <TableRow hover>
              {getDesktopContent(
                id,
                hasHistory,
                equipmentName,
                reasonName,
                userName,
                priorityElement,
                getFormattedDate(createdAt),
                statusElement
              )}
              <TableCell padding="checkbox">
                <FailureActionButton onClick={() => openFailureResolving(id)} />
              </TableCell>
            </TableRow>
            {failureId === id && (
              <FailureHistory
                failureCreatedAt={createdAt || new Date()}
                opened={id === failureId}
                // TODO: 0 should be removed
                failureId={failureId || 0}
              />
            )}
          </React.Fragment>
        );
      }
    );
  };

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            {!isMobile && (
              <TableRow>
                <TableCell />
                <TableCell>{t('reports.failures.place')}</TableCell>
                <TableCell>{t('reports.failures.reason')}</TableCell>
                <TableCell>{t('reports.failures.user')}</TableCell>
                <TableCell>{t('reports.failures.priority')}</TableCell>
                <TableCell>{t('reports.failures.createdAt')}</TableCell>
                <TableCell>{t('reports.failures.status')}</TableCell>
                <TableCell />
              </TableRow>
            )}
          </TableHead>
          <TableBody>{getTableContent()}</TableBody>
        </Table>
        {performedAction?.failureId && (
          <FailureResolving
            open={Boolean(performedAction?.failureId)}
            onClose={closeFailureResolving}
            failureId={performedAction?.failureId}
            onActionPerformed={performedAction?.onActionPerformed}
            onError={() => openFailureResolving(undefined)}
          />
        )}
      </TableContainer>
    </Card>
  );
};

export default FailuresTable;
