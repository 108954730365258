import React from 'react';
import { Grid } from '@mui/material';
import { Page } from '@factorise/components';
import Password from './PasswordForm';
import { useTranslation } from 'react-i18next';
import { User } from '@factorise/api/types';

export interface UserSettingsViewPageProps {
  permitted: boolean;
  user: User | null;
}
const UserSettingsViewPage: React.FC<UserSettingsViewPageProps> = ({
  permitted,
  user,
}) => {
  const { t } = useTranslation();

  return (
    <Page title={t('menu.userSettings')} permitted={permitted}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item lg={6}>
          {user && user.id && <Password userId={user.id} />}
        </Grid>
      </Grid>
    </Page>
  );
};

export default UserSettingsViewPage;
