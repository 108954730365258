import React from 'react';
import { Grid, TextField, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ListBoxOption } from 'types';
import { SelectWithLabel } from '@factorise/components';

export interface RejectFormState {
  rejectReasons: number[];
  comment?: string;
}

export interface RejectFormProps {
  formState?: Partial<RejectFormState>;
  reasons: ListBoxOption[];
  onChange: (key: string, value: string | number) => void;
}

const RejectForm: React.FunctionComponent<RejectFormProps> = ({
  formState,
  reasons,
  onChange,
}) => {
  const { t } = useTranslation();
  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <FormControl fullWidth>
          <SelectWithLabel
            label={t('modals.failureResolving.reason')}
            options={reasons}
            required
            multiple
            name="REASON"
            onChange={onFieldChange}
            value={formState?.rejectReasons || []}
          />
        </FormControl>
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          value={formState?.comment || ''}
          name="COMMENT"
          onChange={onFieldChange}
          fullWidth
          label={t('modals.failureResolving.comment')}
        />
      </Grid>
    </Grid>
  );
};

export default RejectForm;
