function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription: PushSubscription) {
  const API_PORT = process.env.REACT_APP_API_PORT;
  return fetch(
    `https://${window.location.hostname}:${API_PORT}/api/notifications/subscribe`,
    {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  );
}

function sendUnsubscription(subscription: PushSubscription) {
  const API_PORT = process.env.REACT_APP_API_PORT;
  return fetch(
    `https://${window.location.hostname}:${API_PORT}/api/notifications/unsubscribe`,
    {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  );
}

const subscribeUser = () => {
  const { REACT_APP_PUBLIC_VAPID_KEY } = process.env;
  if (!REACT_APP_PUBLIC_VAPID_KEY) {
    throw new Error(
      `Incomplete .env config - REACT_APP_PUBLIC_VAPID_KEY:${REACT_APP_PUBLIC_VAPID_KEY}`
    );
  }
  const convertedVapidKey = urlBase64ToUint8Array(REACT_APP_PUBLIC_VAPID_KEY);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          return;
        }

        registration.pushManager
          .getSubscription()
          .then(function (existedSubscription) {
            if (existedSubscription === null) {
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true,
                })
                .then(function (newSubscription) {
                  sendSubscription(newSubscription);
                })
                .catch(function (e) {
                  if (Notification.permission !== 'granted') {
                  } else {
                    console.error(
                      'An error ocurred during the subscription process.',
                      e
                    );
                  }
                });
            } else {
              sendSubscription(existedSubscription);
            }
          });
      })
      .catch(function (e) {
        console.error(
          'An error ocurred during Service Worker registration.',
          e
        );
      });
  }
};

const unsubscribeUser = () => {
  navigator.serviceWorker.ready.then(function (reg) {
    reg.pushManager.getSubscription().then(function (subscription) {
      subscription
        ?.unsubscribe()
        .then(function (successful) {
          sendUnsubscription(subscription);
        })
        .catch(function (e) {
          // Unsubscribing failed
        });
    });
  });
};

export { subscribeUser, unsubscribeUser };
