import { useLocalStorage, writeStorage } from '@rehooks/local-storage';

const STORAGE_KEY = 'UserNotifications';

type Screen =
  | 'dashboard'
  | 'users'
  | 'products'
  | 'authentication'
  | 'account'
  | 'userSettings'
  | 'finishedGoods'
  | 'factorise'
  | 'failures'
  | 'orders';

export type UserNotification = {
  severity: 'error' | 'warning' | 'info';
  message: string;
  screenName: Screen;
  date: Date;
  endpoint?: string;
};

const useUserNotifications = () => {
  const [notifications] = useLocalStorage<UserNotification[]>(STORAGE_KEY);
  return notifications || [];
};

function addUserNotification(props: UserNotification) {
  const currentNotifications = localStorage.getItem(STORAGE_KEY);
  if (currentNotifications) {
    const parsedNotifications: UserNotification[] =
      JSON.parse(currentNotifications);

    !parsedNotifications.find(
      ({ screenName, endpoint }) =>
        screenName === props.screenName && endpoint === props.endpoint
    ) && writeStorage(STORAGE_KEY, [...parsedNotifications, props]);
  } else {
    writeStorage(STORAGE_KEY, [props]);
  }
}

function clearUserNotifications() {
  writeStorage(STORAGE_KEY, "");
}
export { addUserNotification, useUserNotifications, clearUserNotifications };
