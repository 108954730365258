import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  passwordContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmPassword: {
    marginLeft: 2,
  },
} as const;

export interface RegisterViewPageProps {
  onSubmit: (user: RegistrationForm,
    onLoginDuplicated: () => void,
    onOrganizationDuplicated: () => void) => void;
}
export interface RegistrationForm {
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  login: string,
  passwordConfirmation?: string,
  organization: string,
}
const RegisterViewPage: React.FC<RegisterViewPageProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const initialFormValues: RegistrationForm = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    login: '',
    passwordConfirmation: '',
    organization: '',
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('general.formFieldError.email')).max(255).required(t('general.formFieldError.requiredError')),
    login: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    firstName: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    lastName: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    password: Yup.string()
      .min(8, t('auth.signUp.passwordMinLength'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    passwordConfirmation: Yup.string().when('password', {
      is: (val: any) => val && val.length,
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        t('auth.signUp.differentPassword')
      ),
    }),
    organization: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
  });

  const onFormSubmit = (values: any, helpers: any) => {
    const { setSubmitting, setFieldError } = helpers;
    const { email, firstName, lastName, password, login, organization } = values;
    const user = {
      email,
      firstName,
      lastName,
      password,
      login,
      registrationDate: new Date(),
      organization
    };

    const onDuplicatedLoginError = () => {
      setFieldError('login', t('auth.signUp.userLoginExists'));
    };
    const onDuplicatedOrganizationError = () => {
      setFieldError('organization', t('auth.signUp.organizationExists'));
    };
    setSubmitting(false);
    onSubmit(user, onDuplicatedLoginError, onDuplicatedOrganizationError);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {t('auth.signUp.headerPrimary')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {t('auth.signUp.headerSecondary')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.login && errors.login)}
                  fullWidth
                  helperText={touched.login && errors.login}
                  label={t('auth.signUp.loginPlaceholder')}
                  margin="normal"
                  name="login"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="login"
                  value={values.login}
                  variant="outlined"
                  required
                />
                <TextField
                  error={Boolean(touched.organization && errors.organization)}
                  fullWidth
                  helperText={touched.organization && errors.organization}
                  label={t('auth.signUp.organizationPlaceholder')}
                  margin="normal"
                  name="organization"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="organization"
                  value={values.organization}
                  variant="outlined"
                  required
                />
                <Box sx={styles.passwordContainer}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={t('auth.signUp.passwordPlaceholder')}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    autoComplete="new-password"
                    value={values.password}
                    variant="outlined"
                    required
                  />
                  <TextField
                    sx={styles.confirmPassword}
                    error={Boolean(
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                    )}
                    fullWidth
                    helperText={
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                    }
                    label={t('auth.signUp.passwordConfirmationPlaceholder')}
                    margin="normal"
                    name="passwordConfirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    autoComplete="new-password"
                    value={values.passwordConfirmation}
                    variant="outlined"
                    required
                  />
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label={t('auth.signUp.firstNamePlaceholder')}
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                  required
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label={t('auth.signUp.lastNamePlaceholder')}
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                  required
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('auth.signUp.emailPlaceholder')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  required
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('auth.signUp.signUpButtonText')}
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  {t('auth.signUp.signInQuestion') + ' '}
                  <Link component={RouterLink} to="/login" variant="h6">
                    {t('auth.signUp.signInLinkText')}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Box>
  );
};

export default RegisterViewPage;
