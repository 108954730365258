import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';

export interface PageComponentProps {
  children: React.ReactNode;
  title: string;
}

const styles = {
  root: {
    backgroundColor: 'background.default',
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    width: "100%",
    height: "100%"
  },
} as const;

const PageComponent = forwardRef((props: PageComponentProps, ref: any) => {
  const { title, children } = props;
  return (
    <Box ref={ref} sx={styles.root}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

export default PageComponent;
