import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
} as const;

export default function Loader() {
  return (
    <Box sx={styles.root}>
      <CircularProgress />
    </Box>
  );
}
