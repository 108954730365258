import React from 'react';
import BackdropLoader from './BackdropLoader';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
} from '@mui/material';

const styles = {
  loaderContainer: {
    position: 'relative',
    mt: "8px",
    ml: "8px",
    mr: "8px"
  },
  buttons: {
    mr: 3,
    pb: 2,
  },
} as const;

export interface SimpleDialogProps {
  fullScreen?: boolean;
  showLoader?: boolean;
  open: boolean;
  title: string;
  actions: React.ReactNode;
  children?: React.ReactNode
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  fullScreen = false,
  showLoader = false,
  open,
  title,
  actions,
  children,
}) => {
  return (
    <Dialog fullScreen={fullScreen} open={open} aria-labelledby="title">
      <DialogTitle id="title">{title}</DialogTitle>
      <DialogContent>
        <Card sx={{ margin: '2px' }}>
          <CardContent sx={styles.loaderContainer}>
            {showLoader && <BackdropLoader />}
            {children}
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions sx={styles.buttons}>{actions}</DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
