import React, { useState, useEffect } from 'react';
import FailureViewPage from './FailuresViewPage';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParameterizedQuery } from 'react-fetching-library';
import { ComponentWithErrorAndLoading } from '@factorise/components';
import { FailureActions, EquipmentActions, useQuery, UserActions } from '@factorise/api'
import {
  Equipment,
  FailureReasons,
  User,
  FailurePriority,
  FailureStatus,
  Failure,
} from '@factorise/api/types';
import { FailureFilters } from './types';

const filtersInit: FailureFilters = {
  equipmentId: undefined,
  reasonId: undefined,
  userId: undefined,
  priorityId: undefined,
  statusId: undefined,
};
const ROWS_PER_PAGE = 12;

const FailuresView: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [expandedFailureId, setExpandedFailureId] = useState<number | undefined>();
  const [underModificationFailureId, setUnderModificationFailureId] = useState<number | undefined>();
  const [filterValues, setFilterValues] = useState<FailureFilters>(filtersInit);
  const [page, setPage] = useState<number>(1);

  const failuresQuery = useParameterizedQuery<{ data: { rows: Failure[]; count: number } }>(FailureActions.getFailures);
  const equipmentQuery = useQuery<{ count: number; rows: Equipment[] }>(EquipmentActions.getEquipment({
    filters: { levelTag: "MACHINE" }
  }));
  const reasonsQuery = useQuery<FailureReasons[]>(FailureActions.getFailureReasons({}));
  const usersQuery = useQuery<{ data: { count: number; rows: User[] } }>(UserActions.getUser({}));
  const prioritiesQuery = useQuery<FailurePriority[]>(FailureActions.getFailurePriorities());
  const statusesQuery = useQuery<FailureStatus[]>(FailureActions.getFailureStatuses());

  const { query: refreshFailures } = failuresQuery;

  useEffect(() => {
    refreshFailures({ page, rowsPerPage: ROWS_PER_PAGE, filters: filterValues });
  }, [page, filterValues, refreshFailures]);

  const onActionPerformed = () => {
    setUnderModificationFailureId(undefined);
    setExpandedFailureId(undefined);
    refreshFailures({ page, rowsPerPage: ROWS_PER_PAGE });
  };

  const onFilter = (filterValue: FailureFilters | '') => {
    setFilterValues(filterValue || filtersInit);
  };

  const toggleFailureExpand = (id?: number) => {
    id === expandedFailureId
      ? setExpandedFailureId(undefined)
      : setExpandedFailureId(id);
  };

  if (failuresQuery.error) {
    enqueueSnackbar(t('reports.failures.fetchingDataError'), {
      variant: 'error',
      preventDuplicate: true,
    });
  }

  const equipmentData = equipmentQuery.response?.payload?.rows;
  const reasonsData = reasonsQuery.response?.payload;
  const usersData = usersQuery.response?.payload?.data?.rows;
  const prioritiesData = prioritiesQuery.response?.payload;
  const statusesData = statusesQuery.response?.payload;
  const failuresData = failuresQuery?.payload?.data;

  const loading =
    equipmentQuery.loading ||
    reasonsQuery.loading ||
    usersQuery.loading ||
    prioritiesQuery.loading ||
    statusesQuery.loading ||
    failuresQuery.loading;

  const error =
    equipmentQuery.error ||
    reasonsQuery.error ||
    usersQuery.error ||
    prioritiesQuery.error ||
    statusesQuery.error ||
    failuresQuery.error;

  return (
    <ComponentWithErrorAndLoading loading={loading} error={error}>
      <FailureViewPage
        permitted={true}
        accessesLoading={false}
        currentFilters={filterValues}
        filterOptions={{
          equipment: equipmentData,
          reasons: reasonsData,
          users: usersData,
          priorities: prioritiesData,
          statuses: statusesData,
        }}
        onFilter={onFilter}
        failures={failuresData?.rows || []}
        pagination={{
          page,
          onPageChange: setPage,
          rowsTotal: failuresData?.count || 0,
          rowsPerPage: ROWS_PER_PAGE,
        }}
        performedAction={{
          failureId: underModificationFailureId,
          changeFailureId: setUnderModificationFailureId,
          onActionPerformed,
        }}
        failureDetails={{
          failureId: expandedFailureId,
          changeFailureId: toggleFailureExpand,
        }}
      />
    </ComponentWithErrorAndLoading>
  );
};

export default FailuresView;
