import React from 'react';
import {
  Grid,
  TextField,
  Button,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Formik, FastField, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { SelectWithLabel } from '@factorise/components';
import { SimpleDialog } from '@factorise/components';
import { ListBoxOption } from 'types';
import { User } from '@factorise/api/types';
import Profile from './Profile';
import * as Yup from 'yup';
import getInitials from 'utils/getInitials';

const styles = {
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    minWidth: '320px',
  },
  fieldsSection: {
    pt: 3,
  },
  confirmMPassword: {
    ml: 2,
  },
} as const;

export interface ModifyUserFormProps {
  onClose: () => void;
  onSubmit: (
    user: User,
    showFormError: (fields: string[]) => void,
    onResetForm: () => void
  ) => void;
  roles?: ListBoxOption[];
  user?: User,
}

const ModifyUserForm: React.FunctionComponent<ModifyUserFormProps> = ({
  onClose,
  onSubmit,
  roles = [],
  user
}) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const { t } = useTranslation();
  const editMode = !!user

  const initialFormValues = {
    login: user?.login || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    roleIds: user?.roles?.map(({ id }) => id) || [],
    avatar: new File([''], ''),
  }

  const formValidationSchema = Yup.object().shape({
    login: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    firstName: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    lastName: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    email: Yup.string()
      .email()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char')),
    phone: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char')),
    roleIds: Yup.array().of(Yup.number()).min(1, t('general.formFieldError.requiredError'))
  });

  const onFormSubmit = (values: any, helpers: any) => {
    const { resetForm, setSubmitting, setFieldError } = helpers;

    const showFormError = (fields: string[]) => {
      setSubmitting(false);
      fields.includes('login') && setFieldError('login', t('auth.signUp.userLoginExist'));
      // FIXME: change translation key for existing email error
      fields.includes('email') && setFieldError('email', t('auth.signUp.userLoginExist'));
    };

    onSubmit(values as User, showFormError, resetForm);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={formValidationSchema}
      onSubmit={onFormSubmit}
    >
      {({
        submitForm,
        touched,
        errors,
        values,
        setFieldValue,
      }) => (
        <SimpleDialog
          fullScreen={fullScreen}
          open={true}
          title={t('reports.users.modalTitle')}
          actions={
            <>
              <Button autoFocus onClick={onClose} color="secondary">
                {t('reports.users.modalCancel')}
              </Button>
              <Button
                disabled={Object.keys(errors)?.length > 0 || Object.keys(touched)?.length === 0}
                type="submit"
                color="primary"
                variant="contained"
                onClick={submitForm}
              >
                {t(editMode ? 'general.buttons.save' : 'general.buttons.add')}
              </Button>
            </>
          }
        >
          <Grid
            container
            sx={styles.formContainer}
            direction="column"
            spacing={1}
          >
            <Typography variant="h6">
              {t('reports.users.employeeData')}
            </Typography>
            <Grid item sx={styles.previewContainer} md={12} xs={12}>
              <Field
                required
                avatarText={getInitials(
                  `${values.firstName} ${values.lastName}`
                )}
                fullWidth
                name="avatar"
                onChange={(event: any) =>
                  setFieldValue(event.target.name, event.target.files[0])
                }
                as={Profile}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FastField
                error={errors.firstName && touched.firstName}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                label={t('reports.users.firstName')}
                required
                name="firstName"
                as={TextField}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FastField
                error={errors.lastName && touched.lastName}
                helperText={touched.lastName && errors.lastName}
                fullWidth
                label={t('reports.users.lastName')}
                required
                name="lastName"
                as={TextField}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FastField
                error={errors.phone && touched.phone}
                helperText={touched.phone && errors.phone}
                fullWidth
                label={t('reports.users.phone')}
                name="phone"
                as={TextField}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FastField
                error={errors.email && touched.email}
                helperText={touched.email && errors.email}
                fullWidth
                label={t('reports.users.email')}
                name="email"
                as={TextField}
              />
            </Grid>
            <Typography sx={styles.fieldsSection} variant="h6">
              {t('reports.users.userData')}
            </Typography>
            <Grid item md={12} xs={12}>
              <FastField
                disabled={editMode}
                error={errors.login && touched.login}
                helperText={touched.login && errors.login}
                name="login"
                fullWidth
                label={t('reports.users.login')}
                required
                as={TextField}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Field
                multiple
                value={values.roleIds || []}
                error={errors.roleIds && touched.roleIds}
                helperText={touched.roleIds && errors.roleIds}
                label={t('reports.users.roles')}
                options={roles}
                required
                name="roleIds"
                as={SelectWithLabel}
              />
            </Grid>
          </Grid>
        </SimpleDialog>
      )}
    </Formik>
  );
};

export default ModifyUserForm;
