import { Box } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const styles = {
  desktop: { display: { xs: 'none', sm: 'flex' } },
  mobile: { display: { xs: 'flex', sm: 'none' } },
};

const Topbar = () => {
  return (
    <AppBar
      style={{
        boxShadow:
          '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        display: 'flex',
        backgroundColor: 'white',
      }}
    >
      <Toolbar variant="dense">
        <Box sx={styles.desktop}>
          <RouterLink to="/">
            <img
              style={{ height: '32px', marginLeft: '10px' }}
              alt="Logo"
              src="/images/logos/logo.svg"
            />
          </RouterLink>
        </Box>
        <Box sx={styles.mobile}>
          <RouterLink to="/">
            <img
              style={{ height: '32px', marginLeft: '10px' }}
              alt="Logo"
              src="/images/logos/arrow64.svg"
            />
          </RouterLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
