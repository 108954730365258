import React from 'react';
import { Popover } from '@mui/material';
import { Box } from '@mui/material';

const styles = {
  root: {
    padding: 2,
    width: '400px',
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
} as const;

export interface NotificationsPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  children: React.ReactElement;
}
const NotificationsPopover: React.FunctionComponent<
  NotificationsPopoverProps
> = ({ children, anchorEl, handleClose }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.root}>{children}</Box>
    </Popover>
  );
};

export default NotificationsPopover;
