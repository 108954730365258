import React, { useState } from 'react';
import ModifyUser from './ModifyUser';
import { Role, User } from '@factorise/api/types';
import { TableToolbar } from '@factorise/components';
import { UserActions } from '@factorise/api';
import { useParameterizedQuery } from 'react-fetching-library';
import { useMutation } from 'react-fetching-library';

export interface UsersToolbarProps {
  roles?: Role[];
  users: User[];
  reloadUsers: () => void;
  pagination: {
    page: number;
    onPageChange: (page: number) => void;
    onLimitChange: (page: number) => void;
    rowsTotal: number;
    rowsPerPage: number;
  };
  selectedUserIds?: number[]
}

const UsersToolbar: React.FC<UsersToolbarProps> = ({ roles, reloadUsers, pagination, selectedUserIds }) => {
  const { rowsTotal, page, rowsPerPage, onPageChange, onLimitChange } = pagination
  const editButtonDisabled = !selectedUserIds || selectedUserIds?.length !== 1
  const deleteButtonDisabled = !selectedUserIds || selectedUserIds?.length < 1
  const { mutate: removeUser, reset: resetRemoveUser } = useMutation(UserActions.removeUser);
  const { query: getUser } = useParameterizedQuery(UserActions.getUser);
  const [userModification, setUserModification] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>();
  const [dialogOpened, setDialogOpened] = useState(false);

  const onAddButtonClick = () => {
    setSelectedUser(undefined)
    setDialogOpened(true);
    setUserModification(false)
  };

  const onEditButtonClick = async () => {
    if (selectedUserIds?.length !== 1) return
    const user = await getUser({ id: selectedUserIds[0] })
    setSelectedUser(user.payload.data)
    setDialogOpened(true)
    setUserModification(true)
  }
  const onDeleteButtonClick = () => {
    if (selectedUserIds) removeUser({ id: selectedUserIds }).then(
      () => {
        resetRemoveUser()
        reloadUsers?.()
      }
    )
  }

  return (
    <>
      <TableToolbar
        editButtonDisabled={editButtonDisabled}
        deleteButtonDisabled={deleteButtonDisabled}
        onAddButtonClick={onAddButtonClick}
        onEditButtonClick={onEditButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        pagination={
          {
            totalCount: rowsTotal,
            currentPage: page,
            pageSize: rowsPerPage,
            onPageChange,
            onLimitChange,
          }
        }
      />
      {dialogOpened &&
        <ModifyUser
          reloadUsers={reloadUsers}
          roles={roles}
          selectedUserIds={selectedUserIds}
          editMode={userModification}
          selectedUser={selectedUser}
          onDialogClose={() => setDialogOpened(false)}
        />
      }
    </>
  );
};

export default UsersToolbar;
