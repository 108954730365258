import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
} from '@mui/material';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type PasswordChangeFields = {
  current: string;
  new: string;
};

export interface AddUserFormProps {
  onSubmit: (
    password: PasswordChangeFields,
    onPasswordChanged: () => void,
    onWrongPassword: () => void
  ) => void;
}

const AddUserForm: React.FunctionComponent<AddUserFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();

  const initialFormValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const formValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    newPassword: Yup.string()
      .min(8, t('auth.signUp.passwordMinLength'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    confirmPassword: Yup.string().when('newPassword', {
      is: (val: any) => val && val.length,
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        t('auth.signUp.differentPassword')
      ),
    })
      .required(t('general.formFieldError.requiredError')),
  });

  const onFormSubmit = (values: any, helpers: any) => {
    const { resetForm, setFieldError, setSubmitting } = helpers;

    const passwordChangeFields: PasswordChangeFields = {
      current: values.currentPassword,
      new: values.newPassword,
    };

    const onWrongPassword = () => {
      setFieldError('currentPassword', t('auth.login.wrongCredentials'));
    };
    onSubmit(
      passwordChangeFields as PasswordChangeFields,
      resetForm,
      onWrongPassword
    );
    setSubmitting(false);
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialFormValues}
      validationSchema={formValidationSchema}
      onSubmit={onFormSubmit}
    >
      {({ errors, handleChange, handleSubmit, touched, isValid, dirty, isValidating }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader={t('userSettings.updatePasswordSubheader')}
              title={t('userSettings.updatePasswordHeader')}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Field
                    error={errors.currentPassword && touched.currentPassword}
                    helperText={
                      touched.currentPassword && errors.currentPassword
                    }
                    fullWidth
                    label={t('userSettings.currentPassword')}
                    required
                    name="currentPassword"
                    type="password"
                    as={TextField}
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <Field
                    error={errors.newPassword && touched.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    fullWidth
                    label={t('userSettings.newPassword')}
                    required
                    name="newPassword"
                    type="password"
                    as={TextField}
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <Field
                    error={errors.confirmPassword && touched.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    fullWidth
                    label={t('userSettings.confirmPassword')}
                    required
                    name="confirmPassword"
                    type="password"
                    as={TextField}
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                disabled={!(isValid && dirty) && !isValidating}
                type="submit"
                variant="contained"
              >
                {t('general.buttons.update')}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AddUserForm;
