import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  ButtonGroup,
} from '@mui/material';
import ResolveForm, { ResolveFormState } from './ResolveForm';
import RejectForm, { RejectFormState } from './RejectForm';
import { useTranslation } from 'react-i18next';
import { ListBoxOption } from 'types';

const styles = {
  root: {
    width: '100%',
  },
  button: {
    mt: 2,
  },
} as const;

export interface FailureStepperProps {
  onActionAdd: (type: 'RESOLVED' | 'REJECTED') => void;
  handleResolveFormChange: (key: string, value: string | number) => void;
  handleRejectFormChange: (key: string, value: string | number) => void;
  rejectFormState?: Partial<RejectFormState>;
  resolveFormState?: Partial<ResolveFormState>;
  parts: ListBoxOption[];
  reasons: ListBoxOption[];
}

const FailureStepper: React.FunctionComponent<FailureStepperProps> = ({
  onActionAdd,
  handleResolveFormChange,
  handleRejectFormChange,
  rejectFormState,
  resolveFormState,
  parts,
  reasons,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [failureAcceptance, setFailureAcceptance] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const confirmButtonEnabled = () => {
    const isResolveFormValid =
      failureAcceptance &&
      resolveFormState?.usedParts &&
      resolveFormState.usedParts.length > 0;
    const isRejectFormValid =
      !failureAcceptance &&
      rejectFormState?.rejectReasons &&
      rejectFormState.rejectReasons.length > 0;

    return isResolveFormValid || isRejectFormValid;
  };

  const backButton = (
    <Button
      onClick={handleBack}
      sx={styles.button}
      color="secondary"
      disabled={activeStep === 0}
    >
      {t('modals.failureResolving.back')}
    </Button>
  );

  const yesNoButtons = (
    <ButtonGroup>
      <Button
        variant="outlined"
        onClick={() => {
          setFailureAcceptance(false);
          handleNext();
        }}
        sx={styles.button}
        color="primary"
      >
        {t('general.buttons.no')}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          setFailureAcceptance(true);
          handleNext();
        }}
        sx={styles.button}
        color="primary"
      >
        {t('general.buttons.yes')}
      </Button>
    </ButtonGroup>
  );

  const confirmButton = (
    <Button
      variant="outlined"
      onClick={() => onActionAdd(failureAcceptance ? 'RESOLVED' : 'REJECTED')}
      sx={styles.button}
      color="primary"
      disabled={!confirmButtonEnabled()}
    >
      {failureAcceptance
        ? t('modals.failureResolving.failureAcceptanceResolve')
        : t('modals.failureResolving.failureAcceptanceMoreActions')}
    </Button>
  );

  return (
    <form style={styles.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0}>
          <StepLabel> {t('modals.failureResolving.step')} 1</StepLabel>
          <StepContent>
            <Typography>
              {' '}
              {t('modals.failureResolving.ableToFixQuestion')}{' '}
            </Typography>
            {yesNoButtons}
          </StepContent>
        </Step>
        <Step key={1}>
          <StepLabel>{t('modals.failureResolving.step')} 2</StepLabel>
          <StepContent>
            {failureAcceptance ? (
              <ResolveForm
                parts={parts}
                onChange={handleResolveFormChange}
                formState={resolveFormState}
              />
            ) : (
              <RejectForm
                reasons={reasons}
                onChange={handleRejectFormChange}
                formState={rejectFormState}
              />
            )}
            {backButton} {confirmButton}
          </StepContent>
        </Step>
      </Stepper>
    </form>
  );
};
export default FailureStepper;
