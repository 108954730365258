import { createClient } from 'react-fetching-library';

const API_PORT = process.env.REACT_APP_API_PORT;
const requestHostInterceptor =
  (host: string) => (client: any) => async (action: any) => {
    return {
      ...action,
      credentials: 'include',
      endpoint: `${host}${action.endpoint}`,
    };
  };

const createApiClient = (on401Error?: any) =>
  createClient({
    requestInterceptors: [
      requestHostInterceptor(`https://${window.location.hostname}:${API_PORT}`),
    ],
  });

export default createApiClient