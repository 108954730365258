import React from 'react'
import UsersIcon from '@mui/icons-material/PeopleAltOutlined';
import FailuresIcon from '@mui/icons-material/Engineering';
import EquipmentStatusesIcon from '@mui/icons-material/PrecisionManufacturing';

import {
  UserSettingsView,
  UsersView,
  FailuresView,
  EquipmentStatusesView
} from './index';

type AccessTag = "USERS_BROWSER" | "USER_SETTINGS_BROWSER" | "FAILURES_BROWSER" | 'EQUIPMENT_STATUSES_BROWSER';

interface View {
  title: string,
  path: string,
  accessTag: AccessTag,
  icon?: React.ReactElement,
  inSidebar: boolean,
  element: React.ReactElement
}

export const viewsList: View[] = [
  {
    title: 'menu.users',
    path: 'users',
    accessTag: 'USERS_BROWSER',
    icon: <UsersIcon />,
    inSidebar: true,
    element: <UsersView />,
  },
  {
    path: 'userSettings',
    title: 'menu.userSettings',
    accessTag: 'USER_SETTINGS_BROWSER',
    inSidebar: false,
    element: <UserSettingsView />,
  },
  {
    title: 'menu.failures',
    path: 'failures',
    accessTag: 'FAILURES_BROWSER',
    icon: <FailuresIcon />,
    inSidebar: true,
    element: <FailuresView />,
  },
  {
    title: 'menu.equipmentStatuses',
    path: 'app/equipmentStatuses',
    accessTag: 'EQUIPMENT_STATUSES_BROWSER',
    icon: <EquipmentStatusesIcon />,
    inSidebar: true,
    element: <EquipmentStatusesView />,
  },
];