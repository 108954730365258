import { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { viewsList } from 'views/viewsList'
interface NavigateToDefaultProps {
}

const NavigateToDefault: React.FunctionComponent<NavigateToDefaultProps> = () => {
  const { user, availableViews } = useContext(AuthContext)
  const defaultView = user?.defaultView?.tag || availableViews[0]

  const defaultViewPath = viewsList.find(view => {
    return view.accessTag === defaultView
  })?.path

  if (defaultView && defaultViewPath) {
    return <Navigate to={`/app/${defaultViewPath}`} />
  }

  return <Navigate to={'/welcome'} />;
}

export default NavigateToDefault;