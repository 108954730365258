import React from 'react';
import { TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface PaginationProps {
  rowsTotal?: number,
  page?: number,
  rowsPerPage?: number
  onPageChange: (page: number) => void,
  onLimitChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ rowsTotal, page, rowsPerPage, onPageChange, onLimitChange }: any) => {
  const { t } = useTranslation();
  const defaultLabelDisplayedRows = ({ from, to, count }: any) => {
    const ofTranslated = t('general.pagination.of')
    const moreThanTranslated = t('general.pagination.moreThan')
    return `${from}-${to} ${ofTranslated} ${count !== -1 ? count : `${moreThanTranslated} ${to}`}`;
  }

  return (
    <table aria-label="custom pagination table">
      <tfoot>
        <tr>
          <TablePagination
            sx={{ borderBottom: 'none' }}
            labelRowsPerPage={t('general.pagination.rowsPerPage')}
            labelDisplayedRows={defaultLabelDisplayedRows}
            count={rowsTotal}
            page={page}
            onPageChange={(e, page) => onPageChange(page)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              rowsTotal >= rowsPerPage && onPageChange(0);
              onLimitChange(parseInt(e.target.value, 10))
            }}
          />
        </tr>
      </tfoot>
    </table>)
}

export default Pagination;
