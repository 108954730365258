import React from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import getFormattedDate from 'utils/getFormattedDate';
import { useTranslation } from 'react-i18next';
import { User } from '@factorise/api/types';

export interface RepairInfoProps {
  user?: User;
}

const styles = {
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  statsIcon: {
    mr: 1,
  },
};

const RepairInfo: React.FunctionComponent<RepairInfoProps> = ({ user }) => {
  const { t } = useTranslation();
  const userString = user ? `${user.firstName} ${user.lastName}` : '';
  return (
    <Card elevation={0} sx={{ marginTop: '2px' }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid sx={styles.statsItem} item md={6} xs={6}>
            <AccessTimeIcon sx={styles.statsIcon} color="primary" />
            <Typography color="textSecondary" display="inline" variant="body2">
              {getFormattedDate(new Date())}
            </Typography>
          </Grid>
          <Grid sx={styles.statsItem} item md={6} xs={6}>
            <PersonIcon sx={styles.statsIcon} color="primary" />
            <Typography color="textSecondary" display="inline" variant="body2">
              {t('modals.failureResolving.resolver')}{' '}
              <strong> {userString} </strong>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RepairInfo;
