import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import './index.css';
import i18n from './i18n';
import { register } from '@factorise/service-worker';
import { ClientContextProvider, createApiClient } from '@factorise/api';
import { AuthContextProvider } from 'contexts/AuthContext';

window.Buffer = window.Buffer || require('buffer').Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const client = createApiClient()

root.render(
  <I18nextProvider i18n={i18n}>
    <SnackbarProvider>
      <ClientContextProvider client={client}>
        <React.StrictMode>
          <BrowserRouter>
            <HelmetProvider>
              <AuthContextProvider>
                <App />
              </AuthContextProvider>
            </HelmetProvider>
          </BrowserRouter>
        </React.StrictMode>
      </ClientContextProvider>
    </SnackbarProvider>
  </I18nextProvider>
);

register(process.env.PUBLIC_URL);
