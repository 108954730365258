import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useTranslation } from 'react-i18next';

export interface AddProductProps {
  onClick: () => void;
  disabled?: boolean
}

const AddProduct: React.FC<AddProductProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('shopfloor.equipmentStatuses.addReason') || ''}>
      <div>
        <IconButton disabled={disabled} onClick={onClick} size="large">
          <AddCircleOutlinedIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default AddProduct;
