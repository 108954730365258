import React, { useContext, useState } from 'react';
import EquipmentStatusesPage from './EquipmentStatusesPage';
import { useLocalStorage } from '@rehooks/local-storage';
import { FailureStatus, Equipment } from '@factorise/api/types';
import { AuthContext } from 'contexts/AuthContext';
import { EquipmentActions, FailureActions, useQuery } from '@factorise/api';

export interface EquipmentStatusesViewProps { }
const LOCAL_STORAGE_KEY = 'equipmentStatusesViewEquipmentId';
const STATUSES_TAGS = ['NEW', 'REJECTED'];

const EquipmentStatusesView: React.FunctionComponent<
  EquipmentStatusesViewProps
> = () => {
  const [equipmentId] = useLocalStorage<number>(LOCAL_STORAGE_KEY);
  const { availableViews } = useContext(AuthContext)
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [selection, setSelection] = useState<number[]>([])

  const failureStatus = useQuery<FailureStatus[]>(FailureActions.getFailureStatuses({ statusTags: STATUSES_TAGS }));
  const equipment = useQuery<{
    rows: Equipment[],
    count: number
  }>(EquipmentActions.getEquipment({
    limit,
    page,
    filters: {
      levelTag: 'WORK_CENTER',
    }
  }));

  const onAllRecordsSelect = () => {
    const allIds = equipment?.response?.payload?.rows.map((user: Equipment) => user.id) || [];
    setSelection((prevState) =>
      prevState.length === allIds.length ? [] : allIds
    );
  };

  const onRecordSelect = (selection: number) => {
    setSelection((prevState) =>
      prevState.includes(selection)
        ? prevState.filter((value) => value !== selection)
        : [...prevState, selection]
    );
  };

  return <EquipmentStatusesPage
    onEquipmentAdd={() => {
      setSelection([])
      equipment.query();
    }}
    equipmentId={equipmentId}
    equipment={equipment?.response?.payload?.rows}
    failureStatuses={failureStatus?.response?.payload}
    loading={equipment.loading || failureStatus.loading}
    error={equipment.error || failureStatus.error}
    accesses={availableViews}
    pagination={{
      page,
      onPageChange: setPage,
      onLimitChange: setLimit,
      rowsTotal: equipment?.response?.payload?.count || 0,
      rowsPerPage: limit,
    }}
    selection={{
      selection,
      onRecordSelect,
      onAllRecordsSelect,
    }}
  />

};

export default EquipmentStatusesView;
