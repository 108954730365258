import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Page } from '@factorise/components';
import { useTranslation } from 'react-i18next';

const styles = {
  image: {
    display: 'inline-block',
    maxWidth: '560px',
    width: '100%',
  },
} as const;

const AccessForbiddenView = () => {
  const { t } = useTranslation();
  return (
    <Page title="Forbidden" permitted>
      <Box width="100%" height="100%" display="flex">
        <Container maxWidth="md" sx={{ alignSelf: 'center' }}>
          <Typography align="center" color="textPrimary" variant="h1">
            {t('components.accessForbidden.header')}
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            {t('components.accessForbidden.body')}
          </Typography>
          <Box textAlign="center">
            <img
              alt="Connection error"
              style={styles.image}
              src="/images/undraw_cancel_u1it.svg"
            />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default AccessForbiddenView;
