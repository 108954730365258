import React from 'react';
import RegisterViewPage from './RegisterViewPage';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AuthActions } from '@factorise/api';
import { RegistrationForm } from './RegisterViewPage'

export interface RegisterViewProps { }

const RegisterView: React.FC<RegisterViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useMutation(AuthActions.register);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const showSnackbar = (variant: 'success' | 'warning', message: string) => {
    enqueueSnackbar(message, { variant, disableWindowBlurListener: true });
  };

  const addUser = async (
    user: RegistrationForm,
    showDuplicatedLoginError: () => void,
    showDuplicatedOrganizationError: () => void
  ) => {
    const { status, payload } = await mutate(user);
    switch (status) {
      case 200:
        showSnackbar('success', t('auth.signUp.addedSuccessfully'));
        navigate('/login', { replace: true });
        break;
      case 409:
        if (payload.code === 'USER_LOGIN_UNIQUE_VIOLATION') {
          showSnackbar('warning', t('auth.signUp.userLoginExists'));
          showDuplicatedLoginError();
        }
        if (payload.code === 'ORGANIZATION_UNIQUE_VIOLATION') {
          showSnackbar('warning', t('auth.signUp.organizationExists'));
          showDuplicatedOrganizationError();
        }
        break;
      default:
    }
  };

  return <RegisterViewPage onSubmit={addUser} />;
};

export default RegisterView;
