import { Action } from "react-fetching-library"

type LoginAction = {
  password: string,
  login: string
  organization: string,
}

type RegisterAction = {
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  login: string,
  organization: string,
}

export const loginAction = ({ password, login, organization }: LoginAction): Action => ({
  method: 'POST',
  endpoint: '/api/logIn',
  body: { password, login, organization },
});

export const logOutAction = (): Action => ({
  method: 'POST',
  endpoint: '/api/logOut',
});

export const getAccesses = (viewAccessTag: string): Action => ({
  method: 'POST',
  endpoint: `/api/users/me/viewAccesses/${viewAccessTag}/accesses`,
});

export const register = (user: RegisterAction): Action => ({
  method: 'POST',
  endpoint: '/api/register',
  body: user
});


