import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  CircularProgress,
  Box,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';

type Option = {
  label: number | string;
  value: number | string;
  parent?: string;
};

export interface SelectWithLabelProps {
  value?: any;
  onChange?: (value: any) => void;
  label: string;
  name?: string;
  options: Option[] | [];
  required?: boolean;
  error?: boolean;
  helperText?: string;
  renderNoneOption?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  info?: boolean;
  loading?: boolean;
}
const SelectWithLabel: React.FunctionComponent<SelectWithLabelProps> = ({
  value,
  onChange,
  label,
  options,
  required,
  name,
  error,
  helperText,
  renderNoneOption,
  multiple,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();

  const getOptions = (options: Option[]) => {
    const noneOption = (
      <MenuItem key="" value="">
        <em>{t('components.selectWithInput.noneOption')}</em>
      </MenuItem>
    );

    const parentsList: string[] = [];
    const optionsList: React.ReactElement[] = [];

    options.forEach(({ label, value, parent }) => {
      if (parent && !parentsList.includes(parent)) {
        parentsList.push(parent);
        optionsList.push(
          <ListSubheader key={parent}> {parent} </ListSubheader>
        );
      }

      optionsList.push(
        <MenuItem key={label} value={value}>
          {label}
        </MenuItem>
      );
    });

    if (renderNoneOption) {
      optionsList.unshift(noneOption);
    }
    return optionsList;
  };

  return (
    <FormControl required={required} fullWidth>
      <InputLabel error={error} id={`select-${label}`}>
        {label}
      </InputLabel>
      <Select
        fullWidth
        error={error}
        name={name}
        multiple={multiple}
        labelId={`select-${label}`}
        value={value || ''}
        onChange={onChange}
        label={label}
        disabled={disabled}
      >
        {loading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : options && options.length > 0 ? (
          getOptions(options)
        ) : (
          <MenuItem disabled>
            {t('components.selectWithInput.noOptionAvailable')}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={!!error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectWithLabel;
