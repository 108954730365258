import React from 'react';
import { Box } from '@mui/material';
import UIPagination from '@mui/material/Pagination';
import { SxProps } from '@mui/system';

const styles: { [key: string]: SxProps } = {
  root: {
    '& > *': {
      display: 'flex',
      justifyContent: 'right',
      m: '10px',
    },
  },
} as const;

export interface PaginationProps {
  totalRowsCount: number;
  rowsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  totalRowsCount,
  rowsPerPage,
  selectedPage,
  onPageSelect,
}) => {
  const pagesCount = Math.ceil(totalRowsCount / rowsPerPage);
  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onPageSelect(page);
  };

  return (
    <Box sx={styles.root}>
      {pagesCount > 1 && (
        <UIPagination
          onChange={onPageChange}
          count={pagesCount}
          page={selectedPage}
        />
      )}
    </Box>
  );
};

export default Pagination;
