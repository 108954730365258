import React, { useState, useContext } from 'react';
import AddButton from './AddButton';
import AddEquipment, { EquipmentForm } from './AddEquipmentForm';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts/AuthContext';
import { addEquipmentAction } from '@factorise/api/src/Actions/equipment'

export interface AddEquipmentContainerProps {
  onEquipmentAdd?: (id: number) => void;
  disabled?: boolean
  levelTag: "WORK_CENTER" | "MACHINE"
  parentId?: number
}

const AddEquipmentContainer: React.FunctionComponent<AddEquipmentContainerProps> = ({
  onEquipmentAdd,
  disabled,
  levelTag,
  parentId
}) => {
  const { user } = useContext(AuthContext)
  const [dialogOpened, setDialogOpened] = useState(false);
  const organizationId = user?.organization.id

  const openDialog = () => {
    setDialogOpened(true);
  };
  const closeDialog = () => {
    setDialogOpened(false);
  };

  const {
    mutate,
    reset,
  } = useMutation(addEquipmentAction);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const submitForm = async (
    data: EquipmentForm,
    resetForm: () => void
  ) => {
    if (!organizationId || !parentId) return
    const { payload } = await mutate({
      name: data.name,
      parentId,
      levelTag
    });

    resetForm();
    reset();

    const translatedMessage = t(levelTag === 'WORK_CENTER' ? 'modals.addingEquipment.stationAdded' : "modals.addingEquipment.equipmentAdded")
    enqueueSnackbar(translatedMessage, {
      variant: 'success',
      disableWindowBlurListener: true,
    });
    onEquipmentAdd?.(payload.data.id);
    closeDialog();
  };

  return (
    <>
      <AddButton disabled={disabled} onClick={openDialog} />
      <AddEquipment
        onClose={closeDialog}
        open={dialogOpened}
        onSubmit={submitForm}
        levelTag={levelTag}
      />
    </>
  );
};

export default AddEquipmentContainer;
