import React, { useContext } from 'react';
import AddWorkCenterModal, { EquipmentForm } from './AddWorkCenterModal';
import { useMutation, EquipmentActions, useQuery } from '@factorise/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts/AuthContext';

export interface AddWorkCenterModalContainerProps {
  onEquipmentAdd?: () => void;
  onClose: () => void
  equipmentId?: number
  mode: 'add' | 'edit'
}

const AddWorkCenterModalContainer: React.FunctionComponent<AddWorkCenterModalContainerProps> = ({
  onEquipmentAdd,
  onClose,
  equipmentId,
  mode
}) => {
  const { user } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { response, loading } = useQuery<any>(EquipmentActions.getSingleEquipment({ id: equipmentId || 0 }));
  const { mutate: addEquipment, reset: resetAddition } = useMutation(EquipmentActions.addEquipmentAction);
  const { mutate: updateEquipment, reset: resetModification } = useMutation(EquipmentActions.updateEquipment);

  const submitForm = async (
    data: EquipmentForm,
    resetForm: () => void
  ) => {
    if (!user?.organization.id) return

    if (mode === "add") {
      await addEquipment({ name: data.name, levelTag: "WORK_CENTER" });
      resetAddition();
      enqueueSnackbar(t('modals.addingEquipment.stationAdded'), {
        variant: 'success',
        disableWindowBlurListener: true,
      });
    } else if (mode === "edit") {
      if (!equipmentId) return
      await updateEquipment({ id: equipmentId, name: data.name });
      resetModification();
      enqueueSnackbar(t('modals.addingEquipment.stationUpdated'), {
        variant: 'success',
        disableWindowBlurListener: true,
      });
    }

    resetForm();
    onEquipmentAdd?.();
  };

  let equipmentName = ""
  if (mode === 'edit') {
    equipmentName = response?.payload?.rows[0]?.name
  }

  if (loading) { return null }
  return (
    <AddWorkCenterModal
      onClose={onClose}
      onSubmit={submitForm}
      equipmentName={equipmentName}
    />
  );
};

export default AddWorkCenterModalContainer;
