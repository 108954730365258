import React, { useContext } from 'react';
import UserSettingsViewPage from './UserSettingsViewPage';
import { AuthContext } from 'contexts/AuthContext';


const UserSettingsView = () => {
  const { user, availableViews } = useContext(AuthContext)

  return (
    <UserSettingsViewPage
      permitted={!!availableViews && availableViews.includes('USER_SETTINGS_BROWSER')}
      user={user}
    />
  );
};

export default UserSettingsView;
