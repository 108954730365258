import React from 'react';
import { Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Profile from './Profile';
import SidebarNav from './SidebarNav';
import SidebarDrawer from './SidebarDrawer';
import { User } from '@factorise/api/types';
import { viewsList } from 'views/viewsList';
import { useTranslation } from 'react-i18next';

const styles = {
  divider: {
    margin: '2, 2',
  },
} as const;

export interface SidebarComponentProps {
  open: boolean;
  isDesktop: boolean;
  onSidebarClose: () => void;
  onSidebarOpen: () => void;
  availableViews: string[];
  user?: User | null;
}

const SidebarComponent = ({
  open,
  onSidebarClose,
  onSidebarOpen,
  isDesktop,
  availableViews,
  user,
}: SidebarComponentProps) => {
  const { t } = useTranslation();

  let menuItems: any[] = [];

  menuItems = viewsList.filter((page) => page.inSidebar)
    .filter((page) => availableViews.includes(page.accessTag))
    .map((page) => ({
      title: t(page.title),
      href: `/app/${page.path}`,
      icon: page.icon,
    }));

  return (
    <SidebarDrawer
      onSidebarClose={onSidebarClose}
      open={isDesktop ? true : open}
      isDesktop={isDesktop}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: open ? 'flex-end' : 'center' }}>
        <IconButton
          onClick={onSidebarOpen}
          size="large"
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Profile user={user} avatarOnly={isDesktop ? !open : false} />
      <Divider sx={styles.divider} />
      <SidebarNav
        onSidebarClose={!isDesktop ? onSidebarClose : undefined}
        compactSize={isDesktop ? !open : false}
        pages={menuItems}
      />
    </SidebarDrawer>
  );
};

export default SidebarComponent;
