import React, { useState } from 'react';
import FailureStepper from './FailureStepper';
import { RejectFormState } from './RejectForm';
import { ResolveFormState } from './ResolveForm';
import { set as _set } from 'lodash';
import {
  EquipmentPart,
  FailureAction,
  FailureActionReason,
  Failure,
  FailureStatus,
} from '@factorise/api/types';
import { useMutation, Action } from 'react-fetching-library';

export interface FailureStepperContainerProps {
  reasons?: FailureActionReason[];
  parts?: EquipmentPart[];
  statuses?: FailureStatus[];
  failure?: Failure;
  onActionPerformed?: () => void;
}

const FailureStepperContainer: React.FunctionComponent<
  FailureStepperContainerProps
> = ({ reasons, parts, statuses, failure, onActionPerformed }) => {
  const [rejectFormState, setRejectFormState] =
    useState<Partial<RejectFormState>>();

  const [resolveFormState, setResolveFormState] =
    useState<Partial<ResolveFormState>>();

  // FIXME: needs to be replaced
  const addFailureAction = (failureAction: Partial<FailureAction>): Action => ({
    method: 'POST',
    endpoint: `/api/failures/${failure?.id}/actions`,
    body: failureAction,
  });

  const { mutate, reset } = useMutation(addFailureAction);

  const handleResolveFormChange = (key: string, value: any) => {
    setResolveFormState((prev: Partial<ResolveFormState> | undefined) => {
      const newState = { ...prev } || {};
      if (key === 'PARTS') {
        _set(newState, 'usedParts', value);
      } else if (key === 'COMMENT') {
        _set(newState, 'comment', value);
      }
      return newState;
    });
  };

  const handleRejectFormChange = (key: string, value: any) => {
    setRejectFormState((prev: Partial<RejectFormState> | undefined) => {
      const newState = { ...prev } || {};
      if (key === 'REASON') {
        _set(newState, 'rejectReasons', value);
      } else if (key === 'COMMENT') {
        _set(newState, 'comment', value);
      }
      return newState;
    });
  };

  const onActionAdd = async (type: 'REJECTED' | 'RESOLVED') => {
    reset();
    const statusId = statuses?.find((statuses) => statuses.tag === type)?.id;
    if (!statusId || !failure?.id) {
      return;
    }

    let newFailureAction: Partial<FailureAction> = {};
    _set(newFailureAction, 'failure.id', failure.id);
    _set(newFailureAction, 'status.id', statusId);

    if (type === 'REJECTED') {
      _set(
        newFailureAction,
        'reasons',
        rejectFormState?.rejectReasons?.map((id) => ({ id }))
      );
      _set(newFailureAction, 'comment', rejectFormState?.comment);
    } else if (type === 'RESOLVED') {
      _set(
        newFailureAction,
        'parts',
        resolveFormState?.usedParts?.map((id) => ({ id }))
      );
      _set(newFailureAction, 'comment', resolveFormState?.comment);
    }

    await mutate(newFailureAction);
    onActionPerformed?.();
  };

  return (
    <FailureStepper
      onActionAdd={onActionAdd}
      handleRejectFormChange={handleRejectFormChange}
      handleResolveFormChange={handleResolveFormChange}
      rejectFormState={rejectFormState}
      resolveFormState={resolveFormState}
      parts={
        parts?.map((part) => ({
          value: part.id,
          label: part.name,
        })) || []
      }
      reasons={
        reasons?.map((reason) => ({
          value: reason.id,
          label: reason.name,
        })) || []
      }
    />
  );
};

export default FailureStepperContainer;
