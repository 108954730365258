import React from 'react';
import {
  Grid,
  TextField,
  Button,
  useMediaQuery,
  InputLabel,
  Box,
  Chip,
  FormHelperText
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Formik, FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { SimpleDialog } from '@factorise/components';
import * as Yup from 'yup';

const styles = {
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    minWidth: '320px',
  },
  fieldsSection: {
    pt: 3,
  },
  confirmMPassword: {
    ml: 2,
  },
  categorySelector: {
    margin: 0.5,
    padding: 1,
  },
} as const;



export interface ReasonForm {
  name: string,
  typeId: number,
}

export interface AddReasonProps {
  onClose: () => void;
  onSubmit: (
    data: ReasonForm,
    onResetForm: () => void
  ) => void;
  open: boolean;
  failureTypes?: {
    value: number,
    label: string
  }[],
}

const AddReason: React.FunctionComponent<AddReasonProps> = ({
  onClose,
  onSubmit,
  open,
  failureTypes,
}) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const { t } = useTranslation();

  const initialFormValues = {
    name: '',
    typeId: undefined
  };

  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    typeId: Yup.number().required(t('general.formFieldError.requiredError')),
  });

  const onFormSubmit = (values: any, helpers: any) => {
    const { resetForm } = helpers;
    onSubmit(values, resetForm);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={formValidationSchema}
      onSubmit={onFormSubmit}
      validateOnChange
    >
      {({
        submitForm,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue
      }) => (
        <SimpleDialog
          fullScreen={fullScreen}
          open={open}
          title={t('modals.addingReason.modalTitle')}
          actions={
            <>
              <Button autoFocus onClick={onClose} color="secondary">
                {t('general.buttons.cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {t('general.buttons.add')}
              </Button>
            </>
          }
        >
          <Grid
            container
            sx={styles.formContainer}
            direction="column"
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              <FastField
                error={errors.name && touched.name}
                helperText={touched.name && errors.name}
                fullWidth
                label={t('modals.addingReason.reason')}
                required
                name="name"
                as={TextField}
              />
            </Grid>

            <Grid item md={10} xs={10}>
              <InputLabel error={!!errors.typeId && !!touched.typeId} required style={{ font: '14px', transform: 'scale(0.75)' }}>
                {t('modals.addingReason.category')}
              </InputLabel>
              <Box>
                {failureTypes && failureTypes.map(({ value, label }) => (
                  <Chip
                    sx={styles.categorySelector}
                    onClick={() => setFieldValue('typeId', value)}
                    key={value}
                    label={label}
                    clickable
                    color={
                      (errors.typeId && touched.typeId) ? 'error' : (values?.typeId === value ? 'primary' : 'default')
                    }
                  />
                ))}
                {errors.typeId && touched.typeId && (
                  <FormHelperText error>{errors.typeId}</FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
        </SimpleDialog>
      )}
    </Formik>
  );
};

export default AddReason; 
