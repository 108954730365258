import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Page } from '@factorise/components';
import { useTranslation } from 'react-i18next';

const styles = {
  image: {
    marginTop: 50,
    marginBottom: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
  footer: {
    marginTop: 5,
  },
} as const;

const WelcomeView = () => {
  const { t } = useTranslation();
  return (
    <Page title={t('info.welcome.title')} permitted>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            {t('info.welcome.header')}
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              style={styles.image}
              src="/images/undraw_login_re_4vu2.svg"
            />
          </Box>

          <Typography align="center" color="textPrimary" variant="subtitle1">
            {t('info.welcome.body')}
          </Typography>
          <Typography
            sx={styles.footer}
            align="left"
            color="textPrimary"
            variant="subtitle1"
          >
            {t('info.welcome.footer')}
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

export default WelcomeView;
