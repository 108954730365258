import React from 'react';
import PageComponent from './PageComponent';
import { Navigate } from 'react-router-dom';
import Loader from './Loader';

export interface PageProps {
  children: React.ReactNode;
  title: string;
  permitted?: boolean;
  showLoader?: boolean;
}

const Page: React.FC<PageProps> = (props: PageProps, ref: any) => {
  const { title, children, permitted, showLoader } = props;
  const getContent = () => {
    if (showLoader) {
      return <Loader />;
    } else if (permitted) {
      return children;
    } else {
      return <Navigate to="/noAccess" replace />;
    }
  };
  return <PageComponent title={title}>{getContent()}</PageComponent>;
};

export default Page;
