import React from 'react';
import { Popover } from '@mui/material';

export interface FiltersPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  fullScreen?: boolean;
}

const FiltersPopover: React.FunctionComponent<FiltersPopoverProps> = ({
  children,
  anchorEl,
  handleClose,
  fullScreen,
}) => {
  const width = fullScreen ? '100%' : '320px';

  return (
    <div>
      <Popover
        PaperProps={{ style: { width } }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

export default FiltersPopover;
