import React, { memo } from 'react';
import EquipmentStatusesTableView from './EquipmentStatusesTable';
import { Equipment, FailureStatus } from '@factorise/api/types';
import { FailureActions, useSubscription } from '@factorise/api';
import { ComponentWithErrorAndLoading } from '@factorise/components';

interface EquipmentStatusesTableProps {
  equipment: Equipment[];
  failureStatuses: FailureStatus[];
  handleActionButtonClick: (equipmentId: number) => void;
  selection: {
    selection: number[];
    onRecordSelect: (id: number) => void;
    onAllRecordsSelect: () => void;
  };
}

const EquipmentStatusesTable: React.FunctionComponent<
  EquipmentStatusesTableProps
> = ({ equipment, failureStatuses, handleActionButtonClick, selection }) => {
  const workstationEquipmentIds = equipment.map(({ id }) => id!);
  const statusIds = failureStatuses.map(({ id }) => id!);

  const filters = {
    parentEquipmentId: workstationEquipmentIds,
    statusId: statusIds
  }
  // FIXME: Handle pagination here
  const failuresDataQuery = useSubscription(
    FailureActions.getFailures({ filters }),
    { period: 10000 }
  );
  const rows = failuresDataQuery.response?.payload?.data?.rows;

  return (
    <ComponentWithErrorAndLoading
      loading={failuresDataQuery.loading}
      error={failuresDataQuery.error}
    >
      <EquipmentStatusesTableView
        equipment={equipment}
        onActionClick={handleActionButtonClick}
        failuresList={rows}
        selection={selection}
      />
    </ComponentWithErrorAndLoading>
  );
};

export default memo(EquipmentStatusesTable);
