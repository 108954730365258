import React, { useContext } from 'react';
import LoginViewPage from './LoginViewPage';
import { AuthContext } from 'contexts/AuthContext';

export interface LoginViewProps { }
const LoginView: React.FC<LoginViewProps> = () => {
  const { logIn } = useContext(AuthContext)

  const signIn = (user: { login: string, password: string, organization: string }, resetForm: () => void) => {
    logIn(user.login, user.password, user.organization).finally(
      () => resetForm()
    )
  };

  return <LoginViewPage onSubmit={signIn} />;
};

export default LoginView;
