import { useEffect, useState, useContext } from 'react';
import UsersViewPage from './UsersViewPage';
import { useQuery } from '@factorise/api';
import { SortingSettings, SortingDirection } from 'types';
import { User, Role } from '@factorise/api/types';
import { UserActions } from '@factorise/api';
import { AuthContext } from 'contexts/AuthContext';
import { useParameterizedQuery } from 'react-fetching-library';
import { useQueryStatusHandler } from 'hooks/useQueryStatusHandler'


const SORTING_INIT = {
  column: 'login',
  direction: 'asc' as const,
}

const UsersView = () => {
  const { availableViews } = useContext(AuthContext)
  const [sorting, setSorting] = useState<SortingSettings>(SORTING_INIT);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [selection, setSelection] = useState<number[]>([]);
  const [statusHandler] = useQueryStatusHandler();

  const {
    loading: usersLoading,
    error: usersError,
    payload: usersResponse,
    query: reloadUsers,
  } = useParameterizedQuery(UserActions.getUsers);

  const rolesQuery = useQuery<{ data: Role[] }>(UserActions.getUserRoles(), statusHandler)

  useEffect(() => {
    reloadUsers({ page, sorting, limit });
  }, [page, sorting, reloadUsers, limit]);


  const onAllRecordsSelect = () => {
    const allIds = usersResponse?.data?.rows.map((user: User) => user.id);
    setSelection((prevState) =>
      prevState.length === allIds.length ? [] : allIds
    );
  };
  const clearSelection = () => {
    setSelection([])
  }

  const onRecordSelect = (selection: number) => {
    setSelection((prevState) =>
      prevState.includes(selection)
        ? prevState.filter((value) => value !== selection)
        : [...prevState, selection]
    );
  };

  const changeSortingDirection = (direction: SortingDirection) => {
    return direction === 'asc' ? 'desc' : 'asc';
  };

  const onSort = (column: string) => {
    setSorting(({ column: currentColumn, direction: currentDirection }) => ({
      column,
      direction:
        currentColumn === column
          ? changeSortingDirection(currentDirection)
          : 'asc',
    }));
  };

  const roles = rolesQuery.response?.payload;
  const loading =
    usersLoading ||
    rolesQuery.loading;

  const error = usersError || rolesQuery.error;
  const usersData = usersResponse?.data

  return (
    <UsersViewPage
      loading={loading}
      error={error}
      users={usersData?.rows}
      permitted={availableViews.includes('USERS_BROWSER')}
      sorting={{
        sorting,
        onSort: onSort,
      }}
      pagination={{
        page,
        onPageChange: setPage,
        onLimitChange: setLimit,
        rowsTotal: usersData?.count,
        rowsPerPage: limit,
      }}
      selection={{
        selection: selection,
        onRecordSelect,
        onAllRecordsSelect,
      }}
      roles={roles?.data}
      reloadUsers={() => {
        clearSelection();
        reloadUsers({ page, sorting, limit: limit })
      }}
    />
  );
};
export default UsersView;
