import React from 'react';
import { Grid, TextField, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ListBoxOption } from 'types';
import { SelectWithLabel } from '@factorise/components';

export interface ResolveFormState {
  usedParts: number[];
  comment?: string;
}

export interface ResolveFormProps {
  formState?: Partial<ResolveFormState>;
  parts: ListBoxOption[];
  onChange: (key: string, value: string | number) => void;
}

const ResolveForm: React.FunctionComponent<ResolveFormProps> = ({
  formState,
  parts,
  onChange,
}) => {
  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name, event.target.value);
  };

  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <FormControl fullWidth>
          <SelectWithLabel
            label={t('modals.failureResolving.usedParts')}
            options={parts}
            required
            multiple
            name="PARTS"
            onChange={onFieldChange}
            value={formState?.usedParts || []}
          />
        </FormControl>
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          value={formState?.comment || ''}
          fullWidth
          label={t('modals.failureResolving.comment')}
          name="COMMENT"
          onChange={onFieldChange}
        />
      </Grid>
    </Grid>
  );
};

export default ResolveForm;
