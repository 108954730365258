import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& > *': {
      margin: '5px',
    },
  },
} as const;

const NoDataReceived = () => {
  const { t } = useTranslation();
  return (
    <Box sx={styles.root}>
      <AssignmentLateTwoToneIcon fontSize="large" color="inherit" />
      <Typography color="inherit" variant="h6">
        {t('components.noDataReceived.body')}
      </Typography>
    </Box>
  );
};

export default NoDataReceived;
