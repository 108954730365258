import React from 'react';
import {
  Avatar,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  Chip,
  Box,
} from '@mui/material';
import getInitials from 'utils/getInitials';
import getFormattedDate from 'utils/getFormattedDate';
import { useTranslation } from 'react-i18next';
import { User } from '@factorise/api/types';
import { SortingSettings } from 'types';
import { ZeroStateView } from '@factorise/components'
const styles = {
  avatar: {
    mr: 2,
  },
  table: {
    minWidth: 1050,
  },
  progress: {
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roleChipsContainer: {
    '& > *': {
      margin: 0.5,
    },
  },
};

export interface UsersTableProps {
  loading?: boolean;
  error?: boolean;
  users: User[];
  sorting: {
    sorting: SortingSettings;
    onSort: (column: string) => void;
  };
  selection: {
    selection: number[];
    onRecordSelect: (id: number) => void;
    onAllRecordsSelect: () => void;
  };
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  sorting,
  selection,
}) => {
  const { t } = useTranslation();
  const {
    selection: currentSelection,
    onRecordSelect,
    onAllRecordsSelect,
  } = selection;

  const getHeader = () => {
    const headerSettings = [
      { key: 'avatar', name: t('reports.users.avatar') },
      { key: 'login', name: t('reports.users.login') },
      { key: 'name', name: t('reports.users.name') },
      { key: 'email', name: t('reports.users.email') },
      { key: 'phone', name: t('reports.users.phone') },
      { key: 'registrationDate', name: t('reports.users.registrationDate') },
      { key: 'roles', name: t('reports.users.roles') },
    ];

    const selectedRowsNumber = selection.selection.length;
    const displayedRowsNumber = users.length;

    const headerCheckbox = [
      <TableCell key="checkbox" padding="checkbox">
        <Checkbox
          onChange={() => onAllRecordsSelect()}
          indeterminate={
            selectedRowsNumber > 0 && selectedRowsNumber < displayedRowsNumber
          }
          checked={
            displayedRowsNumber > 0 &&
            selectedRowsNumber === displayedRowsNumber
          }
        />
      </TableCell>,
    ];

    const { sorting: currentSorting, onSort } = sorting;

    const headerFields = headerSettings.map(({ key, name }) => (
      <TableCell key={name}>
        <TableSortLabel
          active={currentSorting.column === key}
          direction={currentSorting.direction}
          onClick={() => onSort?.(key)}
          disabled={['roles', 'phone', 'avatar'].includes(key)}
        >
          {name}
        </TableSortLabel>
      </TableCell>
    ));
    return [...headerCheckbox, ...headerFields];
  };

  const getTableRows = (users: User[]) =>
    users.map(
      ({
        id,
        firstName,
        lastName,
        login,
        email,
        phone,
        registrationDate,
        roles,
      }) => (
        <TableRow
          role="checkbox"
          onClick={() => onRecordSelect(id || 0)}
          aria-checked={currentSelection.includes(id || 0)}
          hover
          key={id}
        >
          <TableCell padding="checkbox">
            <Checkbox checked={currentSelection.includes(id || 0)} />
          </TableCell>
          <TableCell>
            <Avatar>{getInitials(`${firstName} ${lastName}`)}</Avatar>
          </TableCell>
          <TableCell>{login}</TableCell>
          <TableCell>{`${firstName} ${lastName}`}</TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{phone}</TableCell>
          <TableCell>{getFormattedDate(registrationDate)}</TableCell>
          <TableCell>
            <Box sx={styles.roleChipsContainer}>
              {roles?.map(({ id, name }) => (
                <Chip key={id} label={name} />
              ))}
            </Box>
          </TableCell>
        </TableRow>
      )
    );

  const usersTable = (
    <Card>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>{getHeader()}</TableRow>
          </TableHead>
          <TableBody>{getTableRows(users)}</TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  return users.length > 0 ? usersTable : <ZeroStateView header={t('reports.users.zeroStateHeader')} />
};

export default UsersTable;
