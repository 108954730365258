import React from 'react';
import { Stack, Box } from '@mui/material';
import { Page } from '@factorise/components';
import FailuresTable from './widgets/FailuresTable/FailuresTable';
import { useTranslation } from 'react-i18next';
import { PaginationOld as Pagination } from '@factorise/components';
import { Failure } from '@factorise/api/types';
import { FailureFilterOptions, FailureFilters } from './types';
import Toolbar from './Toolbar';

interface FailureViewPageProps {
  permitted?: boolean;
  accessesLoading?: boolean;
  currentFilters: FailureFilters;
  failures: Failure[];
  performedAction: {
    failureId?: number;
    changeFailureId: (value?: number | undefined) => void;
    onActionPerformed: () => void;
  };
  failureDetails: {
    failureId?: number;
    changeFailureId: (value?: number) => void;
  };
  pagination: {
    page: number;
    onPageChange: (page: number) => void;
    rowsTotal: number;
    rowsPerPage: number;
  };
  filterOptions: FailureFilterOptions;
  onFilter: (filterValue: FailureFilters | '') => void;
}

const FailureViewPage: React.FunctionComponent<FailureViewPageProps> = ({
  accessesLoading,
  permitted,
  failures,
  performedAction,
  failureDetails,
  pagination,
  filterOptions,
  onFilter,
  currentFilters,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Page
        showLoader={accessesLoading}
        title={t('menu.failures')}
        permitted={permitted}
      >
        <Stack sx={{ height: '100%', width: '100%' }} direction="column">
          <Toolbar
            filterOptions={filterOptions}
            onFilter={onFilter}
            currentFilters={currentFilters}
          />
          <Box sx={{ flexGrow: 1 }}>
            <FailuresTable
              failures={failures}
              performedAction={performedAction}
              failureDetails={failureDetails}
            />
          </Box>
          {failures && (
            <Pagination
              onPageSelect={pagination.onPageChange}
              selectedPage={pagination.page}
              totalRowsCount={pagination.rowsTotal}
              rowsPerPage={pagination.rowsPerPage}
            />
          )}
        </Stack>
      </Page>
    </>
  );
};

export default FailureViewPage;
