import RemoveWorkCenter from "./RemoveWorkCenter";
import { useMutation, EquipmentActions } from '@factorise/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface RemoveWorkCenterContainerProps {
  open: boolean;
  onConfirm: () => void,
  onReject: () => void,
  id?: number
}

const RemoveWorkCenterContainer: React.FunctionComponent<RemoveWorkCenterContainerProps> = ({
  open, onConfirm, onReject, id
}) => {
  const { mutate: deleteEquipment, reset: resetDeleteAction } = useMutation(EquipmentActions.deleteEquipment);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const remove = async () => {
    id && await deleteEquipment({ id });
    resetDeleteAction();
    enqueueSnackbar(t('modals.addingEquipment.stationAdded'), {
      variant: 'success',
      disableWindowBlurListener: true,
    });
    onConfirm()
  }
  return <RemoveWorkCenter open={open} onConfirm={remove} onReject={onReject} />;
}

export default RemoveWorkCenterContainer;