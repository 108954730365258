import React, { useState } from 'react';
import { Page } from '@factorise/components';
import EquipmentStatusesTable from './EquipmentStatusesTable';
import { ComponentWithErrorAndLoading } from '@factorise/components';
import { useTranslation } from 'react-i18next';
import FailureReporting from 'modals/failure/reporting';
import { Equipment, FailureStatus } from '@factorise/api/types';
import EquipmentStatusesToolbar from './EquipmentStatusesToolbar';
import { Stack, Box } from '@mui/material';
import { ZeroStateView } from '@factorise/components'

export interface EquipmentStatusesViewProps {
  equipmentId: number | null;
  failureStatuses?: FailureStatus[];
  equipment?: Equipment[];
  loading: boolean;
  error: boolean;
  accesses: string[];
  onEquipmentAdd?: () => void;
  pagination: {
    page: number;
    onPageChange: (page: number) => void;
    onLimitChange: (limit: number) => void;
    rowsTotal: number;
    rowsPerPage: number;
  };
  selection: {
    selection: number[];
    onRecordSelect: (id: number) => void;
    onAllRecordsSelect: () => void;
  };
}

const EquipmentStatusesView: React.FunctionComponent<
  EquipmentStatusesViewProps
> = ({ loading, accesses, error, equipment, failureStatuses, onEquipmentAdd, pagination, selection }) => {
  const { t } = useTranslation();

  const dataFetched = !error && equipment && failureStatuses;

  const [failureReportingOpened, setFailureReportingOpened] =
    useState<boolean>(false);
  const [failureEquipmentId, setFailureEquipmentId] = useState<number>(0);

  const handleActionButtonClick = (id: number) => {
    setFailureEquipmentId(id);
    setFailureReportingOpened(true);
  };
  const closeFailureReporting = () => {
    setFailureReportingOpened(false);
  };
  const failureReportingModal = (<FailureReporting
    equipmentId={failureEquipmentId}
    open={failureReportingOpened}
    onClose={closeFailureReporting}
  />)

  return (
    <Page
      title={t('menu.equipmentStatuses')}
      permitted={accesses.includes('EQUIPMENT_STATUSES_BROWSER')}
      showLoader={loading}
    >
      <ComponentWithErrorAndLoading loading={loading} error={!dataFetched}>
        <Stack sx={{ width: '100%', height: '100%' }} direction="column">
          <EquipmentStatusesToolbar onEquipmentAdd={onEquipmentAdd} pagination={pagination} selectedEquipmentIds={selection.selection} />
          {failureReportingOpened && failureReportingModal}
          {equipment && equipment.length > 0 ?
            <Box sx={{ flexGrow: 1 }}>
              <EquipmentStatusesTable
                handleActionButtonClick={handleActionButtonClick}
                failureStatuses={failureStatuses || []}
                equipment={equipment || []}
                selection={selection}
              />
            </Box>
            :
            <ZeroStateView header={t('reports.equipmentStatuses.zeroStateHeader')} />
          }
        </Stack>
      </ComponentWithErrorAndLoading>
    </Page>
  );
};

export default EquipmentStatusesView;
