import React from 'react';
import { IconButton, Tooltip, Badge } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import { useTranslation } from 'react-i18next';

const styles = {
  button: {
    mr: 1,
  },
} as const;

export interface FailureActionButtonProps {
  badgeContent?: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FailureActionButton: React.FC<FailureActionButtonProps> = ({
  onClick,
  badgeContent,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('reports.failures.actions') || ''}>
      <IconButton sx={styles.button} onClick={onClick} size="large">
        <Badge badgeContent={badgeContent} color="secondary">
          <BuildIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default FailureActionButton;
