import React from 'react';
import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';

const DrawerRoot = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: '64px'
}));

export interface SidebarDrawerProps {
  open: boolean;
  isDesktop: boolean;
  onSidebarClose: () => void;
  children: React.ReactNode;
}

const SidebarDrawer = ({
  open,
  onSidebarClose,
  isDesktop,
  children,
}: SidebarDrawerProps) => {

  return (
    <Drawer
      anchor="left"
      onClose={onSidebarClose}
      open={isDesktop ? true : open}
      variant={isDesktop ? 'persistent' : 'temporary'}
    >
      <DrawerRoot>{children}</DrawerRoot>
    </Drawer>
  );
};

export default SidebarDrawer;
