import React, { useState, useEffect } from 'react';
import FiltersFooter from './FiltersFooter';
import FiltersPopoverView from './FiltersPopover';
import FilterButton from './FilterButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export interface FiltersPopoverProps {
  renderFilters: (
    onChange: (name: string, value: any) => void,
    currentFilters: any
  ) => void;
  appliedFilters?: any;
  onApply?: (filters: any) => void;
  onClear?: () => void;
}

const FiltersPopover: React.FunctionComponent<FiltersPopoverProps> = ({
  renderFilters,
  onApply,
  onClear,
  appliedFilters,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterValues, setFilterValues] = useState<any>(appliedFilters);

  useEffect(() => {
    setFilterValues(appliedFilters);
  }, [appliedFilters]);

  const onFilterValueChange = (name: string, value: any) => {
    setFilterValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const openFiltersPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFiltersPopover = () => {
    setAnchorEl(null);
  };

  const onApplyAction = () => {
    closeFiltersPopover();
    onApply?.(filterValues);
  };

  const onClearAction = () => {
    closeFiltersPopover();
    onClear?.();
  };

  const getAppliedFiltersNumber = () => {
    if (!appliedFilters) {
      return 0;
    }
    const filtersCount = Object.values(appliedFilters).filter(
      (value) => value !== '' && value !== undefined
    ).length;
    return filtersCount;
  };

  return (
    <>
      <FilterButton
        onClick={openFiltersPopover}
        badgeContent={getAppliedFiltersNumber()}
      />
      <FiltersPopoverView
        fullScreen={fullScreen}
        anchorEl={anchorEl}
        handleClose={closeFiltersPopover}
      >
        {renderFilters(onFilterValueChange, filterValues)}
        <FiltersFooter
          onApply={onApplyAction}
          onClear={onClearAction}
          applyFilterDisabled={false}
        />
      </FiltersPopoverView>
    </>
  );
};

export default FiltersPopover;
