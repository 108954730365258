import { Action } from "react-fetching-library"
import { User } from '@factorise/api/types'

export interface SortingSettings {
  column: string;
  direction: 'asc' | 'desc';
}

export type UserFilters = {
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export interface GetUsersAction {
  page: number;
  filters?: UserFilters;
  sorting: SortingSettings;
  limit: number
}
export interface AddUserAction {
  id?: number;
  login: string;
  password?: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  registrationDate?: Date;
  defaultViewId?: number;
  positionId?: number;
  organizationId: number;
  isTempPassword?: boolean;
  roleIds: number[]
}

export interface UpdateUserAction {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  roleIds?: number[]
}

export const me = (): Action => ({
  method: 'GET',
  endpoint: '/api/users/me',
});

export interface MeApiResponse {
  data: {
    user: User,
    viewsAccesses: string[]
  }
}

export const addUser = ({ user, generatePassword }: { user: AddUserAction, generatePassword?: boolean }): Action => ({
  method: 'POST',
  endpoint: '/api/users',
  body: {
    ...user,
    generatePassword
  },
});

export const getUsers = ({
  page,
  filters,
  sorting,
  limit
}: GetUsersAction): Action => {
  const endpoint = '/api/users';
  const method = 'GET';
  const offset = (page) * limit;
  let queryParams: string[] = [];

  if (filters) {
    const { login, firstName, lastName, email, phone } = filters;
    login && queryParams.push(`login=${login}`);
    firstName && queryParams.push(`firstName=${firstName}`);
    lastName && queryParams.push(`lastName=${lastName}`);
    email && queryParams.push(`email=${email}`);
    phone && queryParams.push(`phone=${phone}`);
  }

  queryParams.push(`limit=${limit}`);
  queryParams.push(`offset=${offset}`);
  queryParams.push(`sortingColumn=${sorting.column}`);
  queryParams.push(`sortingDirection=${sorting.direction}`);

  let action: Action = {
    method,
    endpoint: endpoint + '?' + queryParams.join('&'),
  };
  return action;
};

export const getUserRoles = (): Action => ({
  method: 'GET',
  endpoint: '/api/users/roles',
  config: {
    emitErrorForStatuses: [401],
  },
});

export const getUser = ({ id }: { id?: number }): Action => {
  return {
    method: 'GET',
    endpoint: id ? `/api/users/${id}` : '/api/users',
    config: {
      emitErrorForStatuses: [401],
    }
  }
}

export const removeUser = ({ id }: { id: number[] }): Action => ({
  method: 'DELETE',
  endpoint: `/api/users`,
  body: { id },
  config: {
    emitErrorForStatuses: [401],
  },
});

export const updateUser = ({ id, user }: { id: number, user: UpdateUserAction }): Action => ({
  method: 'PUT',
  endpoint: `/api/users/${id}`,
  body: user,
  config: {
    emitErrorForStatuses: [401],
  },
});

export const changePassword = (props: {
  currentPassword: string;
  newPassword: string;
  userId?: number;
}): Action => ({
  method: 'PUT',
  endpoint: `/api/users/${props.userId}/password`,
  body: {
    currentPassword: props.currentPassword,
    newPassword: props.newPassword,
  },
  config: {
    emitErrorForStatuses: [401],
  },
});
