import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import UserNotifications from './UserNotifications';
import {
  useUserNotifications,
} from 'utils/UserNotifications';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

const styles = {
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    mr: "1px",
  },
  openMenuButton: {
    ml: '-15px',
  },
  desktop: { display: { xs: 'none', sm: 'flex' } },
  mobile: { display: { xs: 'flex', sm: 'none' } },
};

type TopBarNavProps = {
  onSidebarOpen: () => void;
};

const Topbar = ({ onSidebarOpen }: TopBarNavProps) => {
  const { t } = useTranslation();
  const { logOut } = useContext(AuthContext)
  const notifications = useUserNotifications();

  const onLogoutAction = async () => {
    await logOut()
  }

  return (
    <AppBar
      style={{
        boxShadow:
          '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        display: 'flex',
        backgroundColor: 'white',
      }}
    >
      <Toolbar variant="dense">
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <IconButton sx={styles.openMenuButton} onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Box sx={styles.desktop}>
          <RouterLink to="/">
            <img
              style={{ height: '32px', marginLeft: '10px' }}
              alt="Logo"
              src="/images/logos/logo.svg"
            />
          </RouterLink>
        </Box>
        <Box sx={styles.mobile}>
          <RouterLink to="/" style={{ marginLeft: '10px', width: "32px", height: "32px" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              alt="Logo"
              src="/images/logos/arrow64.svg"
            />
          </RouterLink>
        </Box>
        <Box sx={styles.flexGrow} />
        <Tooltip title={t('layouts.topbar.profile') || ''}>
          <IconButton
            component={RouterLink}
            to="/app/userSettings"
            sx={styles.signOutButton}
          >
            <ManageAccountsIcon />
          </IconButton>
        </Tooltip>
        <Box>
          <UserNotifications
            notifications={notifications.map((n) => ({
              header: n.screenName,
              message: n.message,
              date: n.date,
              severity: n.severity,
            }))}
          />
        </Box>
        <Tooltip title={t('layouts.topbar.logOut') || ''}>
          <IconButton sx={styles.signOutButton} onClick={onLogoutAction}>
            <InputIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
