import { Role, User } from '@factorise/api/types';
import React, { useState, useContext } from 'react';
import ModifyUserForm from './ModifyUserForm';
import TempPasswordDialog from './TempPasswordDialog';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { UserActions } from '@factorise/api';
import { AuthContext } from 'contexts/AuthContext';


export interface ModifyUserContainerProps {
  reloadUsers?: () => void;
  roles?: Role[];
  selectedUserIds?: number[];
  editMode: boolean,
  selectedUser?: User,
  onDialogClose: () => void
}

const ModifyUserContainer: React.FunctionComponent<ModifyUserContainerProps> = ({
  roles,
  reloadUsers,
  selectedUserIds,
  editMode,
  selectedUser,
  onDialogClose

}) => {
  const { user } = useContext(AuthContext)
  const organizationId = user?.organization.id
  const [tempPassDialogOpened, setTempPassDialogOpened] = useState(false);
  const [tempPassword, setTempPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { mutate: addUser, reset: resetAddUser } = useMutation(UserActions.addUser);
  const { mutate: updateUser, reset: resetUpdateUser } = useMutation(UserActions.updateUser);

  const submitForm = async (
    user: any,
    showFormError: (fields: string[]) => void,
    resetForm: () => void
  ) => {
    if (!organizationId) return

    const { error, payload } = !editMode ? await addUser({
      user: {
        login: user.login,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        organizationId,
        roleIds: user.roleIds,
      }, generatePassword: true
    }) : await updateUser({
      id: (selectedUserIds && selectedUserIds[0]) || 0,
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        roleIds: user.roleIds,
      }
    })

    resetUpdateUser()
    resetAddUser();

    if (error) {
      if (payload.code === 'UNIQUE_VIOLATION') {
        const fields = Object.keys(payload.fields)
        showFormError(fields);
      } else {
        enqueueSnackbar(t('general.queryErrors.actionFailed'), {
          variant: 'error',
          disableWindowBlurListener: true,
        });
      }
      return;
    }

    resetForm();
    enqueueSnackbar(t('reports.users.addedSuccessfully'), {
      variant: 'success',
      disableWindowBlurListener: true,
    });

    payload.data?.password && setTempPassword(payload.data.password);
    payload.data?.password && setTempPassDialogOpened(true);
    editMode && reloadUsers?.()
  };

  return !tempPassDialogOpened ? <ModifyUserForm
    roles={
      roles?.map((role) => ({
        value: role.id,
        label: role.name,
      })) || []
    }
    onClose={() => onDialogClose()}
    onSubmit={submitForm}
    user={selectedUser}
  /> : (
    <TempPasswordDialog
      password={tempPassword}
      onClose={() => {
        reloadUsers?.()
        setTempPassDialogOpened(false)
      }}
    />
  )
}

export default ModifyUserContainer;
