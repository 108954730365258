import * as React from 'react';
import { Card, CardContent, Typography, Box, TableCell } from '@mui/material';

interface MobileRowContentProps {
  equipment: string;
  reason: string;
  priority: React.ReactNode;
  date: string;
  status: React.ReactNode;
}

const MobileRowContent: React.FC<MobileRowContentProps> = ({
  equipment,
  reason,
  priority,
  date,
  status,
}) => {
  return (
    <TableCell>
      <Card elevation={0}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="text.secondary">{equipment}</Typography>
            <Box sx={{ ml: '10px' }}>{status}</Box>
          </Box>
          <Typography variant="h6" component="div">
            {reason}
          </Typography>
          <Typography>{date}</Typography>
          {priority}
        </CardContent>
      </Card>
    </TableCell>
  );
};
export default MobileRowContent;
