import {
  Box,
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableHead,
  TableBody,
  Chip,
  Typography,
} from '@mui/material';
import React from 'react';
import getCssContrastColoring from 'utils/getCssContrastColoring';
import HandymanIcon from '@mui/icons-material/Handyman';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { FailureAction } from '@factorise/api/types';
import getFormattedDate from 'utils/getFormattedDate';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { useTranslation } from 'react-i18next';
import { ComponentWithErrorAndLoading } from '@factorise/components';
import { EquipmentPart } from '@factorise/api/types';

export interface FailureHistoryRowProps {
  loading: boolean;
  error: boolean;
  opened: boolean;
  failureActionHistory?: FailureAction[];
  failureCreatedAt: Date;
}

const getReasonChips = (reasons?: string[]) => {
  return (
    <Box>
      {reasons?.map((reason) => {
        return (
          <Chip
            key={reason}
            sx={{ margin: '2px' }}
            icon={<QuestionMarkIcon />}
            label={reason}
            size="small"
          />
        );
      })}
    </Box>
  );
};

const getPartChips = (parts?: string[]) => {
  return (
    <Box>
      {parts?.map((part) => {
        return (
          <Chip
            key={part}
            sx={{ margin: '2px' }}
            icon={<HandymanIcon />}
            label={part}
            size="small"
          />
        );
      })}
    </Box>
  );
};

const FailureHistoryRow: React.FC<FailureHistoryRowProps> = ({
  opened,
  failureActionHistory,
  loading,
  error,
  failureCreatedAt,
}) => {
  const { t } = useTranslation();

  const content = failureActionHistory?.map(
    (
      { id, comment, createdAt, parts, reasons, user, status },
      index,
      elements
    ) => {
      const prevActionTime = elements[index - 1]?.createdAt;
      const duration = prevActionTime
        ? moment(createdAt).diff(prevActionTime, 'ms')
        : moment(createdAt).diff(failureCreatedAt, 'ms');

      return (
        <TableRow key={id}>
          <TableCell>
            <Chip
              style={getCssContrastColoring(status.color)}
              label={status.name}
              size="small"
            />
          </TableCell>
          <TableCell>{`${user?.firstName} ${user?.lastName}`}</TableCell>
          <TableCell>{getFormattedDate(createdAt)}</TableCell>
          <TableCell>
            {duration &&
              humanizeDuration(duration, { language: 'pl', round: true })}
          </TableCell>
          <TableCell align="right">
            <Box>
              <Typography>{comment}</Typography>
              {/* TODO: add proper typing (get rid of any) */}
              {getPartChips(parts?.map((part: EquipmentPart) => part.name))}
              {getReasonChips(reasons?.map((reason: any) => reason.name))}
            </Box>
          </TableCell>
        </TableRow>
      );
    }
  );

  return (
    <TableRow>
      <TableCell style={{ padding: 0, width: '80%' }} colSpan={8}>
        <Collapse in={opened} timeout="auto" unmountOnExit>
          <Box margin={3}>
            <Typography variant="h6" gutterBottom component="div">
              {t('reports.failures.history')}
            </Typography>
            <ComponentWithErrorAndLoading loading={loading} error={error}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('reports.failures.status')}</TableCell>
                    <TableCell>{t('reports.failures.user')}</TableCell>
                    <TableCell>{t('reports.failures.createdAt')}</TableCell>
                    <TableCell>{t('reports.failures.duration')}</TableCell>
                    <TableCell align="right">
                      {t('reports.failures.details')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{content}</TableBody>
              </Table>
            </ComponentWithErrorAndLoading>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default FailureHistoryRow;
