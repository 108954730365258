import { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';


const useQueryStatusHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { logOut, user } = useContext(AuthContext);
  const [status, setStatus] = useState<number>();
  const { t } = useTranslation();

  const showSessionExpiredError = useCallback(() => {
    enqueueSnackbar(t('layouts.sessionExpired'), {
      variant: 'error',
      preventDuplicate: true,
    });
  }, [enqueueSnackbar, t])

  const errorsHandler = useCallback(() => {
    if (user && status === 401) {
      logOut().then(
        () => showSessionExpiredError()
      )
    }
  }, [status, logOut, user, showSessionExpiredError]);

  useEffect(() => errorsHandler(), [errorsHandler])
  const statusHandler = async (status: number) => { setStatus(status) }

  return [statusHandler]
};

export { useQueryStatusHandler };
