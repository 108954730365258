import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { SelectWithLabel } from '@factorise/components';
import { FailureFilters } from '../../types';
import { ListBoxOption } from 'types';

const styles = {
  root: {
    p: 4,
    width: '100%',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
} as const;

export interface FilterFieldsProps {
  equipment?: ListBoxOption[];
  reasons?: ListBoxOption[];
  users?: ListBoxOption[];
  priorities?: ListBoxOption[];
  statuses?: ListBoxOption[];
  value: FailureFilters;
  onChange?: (name: string, value?: number) => void;
}

const FilterFields: React.FunctionComponent<FilterFieldsProps> = ({
  value: filterValues,
  equipment,
  reasons,
  users,
  priorities,
  statuses,
  onChange,
}) => {
  const { t } = useTranslation();

  const textFieldChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    let returnValue;
    if (typeof value === 'number') {
      returnValue = value;
    } else {
      returnValue = undefined;
    }

    onChange?.(name, returnValue);
  };

  return (
    <Grid spacing={2} container direction="column" sx={styles.root}>
      <Grid item sx={styles.filterContainer}>
        <SelectWithLabel
          label={t('reports.failures.place')}
          options={equipment || []}
          name="equipmentId"
          onChange={textFieldChangeHandler}
          value={filterValues?.equipmentId || ''}
          renderNoneOption
        />
      </Grid>
      <Grid item sx={styles.filterContainer}>
        <SelectWithLabel
          label={t('reports.failures.reason')}
          options={reasons || []}
          name="reasonId"
          onChange={textFieldChangeHandler}
          value={filterValues?.reasonId || ''}
          renderNoneOption
        />
      </Grid>

      <Grid item sx={styles.filterContainer}>
        <SelectWithLabel
          label={t('reports.failures.user')}
          options={users || []}
          name="userId"
          onChange={textFieldChangeHandler}
          value={filterValues?.userId || ''}
          renderNoneOption
        />
      </Grid>
      <Grid item sx={styles.filterContainer}>
        <SelectWithLabel
          label={t('reports.failures.priority')}
          options={priorities || []}
          name="priorityId"
          onChange={textFieldChangeHandler}
          value={filterValues?.priorityId || ''}
          renderNoneOption
        />
      </Grid>
      <Grid item sx={styles.filterContainer}>
        <SelectWithLabel
          label={t('reports.failures.status')}
          options={statuses || []}
          name="statusId"
          onChange={textFieldChangeHandler}
          value={filterValues?.statusId || ''}
          renderNoneOption
        />
      </Grid>
    </Grid>
  );
};

export default FilterFields;
