import React, { useState, useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import constants from './../constants'

const styles = {
  shiftContent: {
    paddingLeft: constants.NAV_BAR_OPENED_WIDTH,
  },
  shiftContentCompact: {
    paddingLeft: constants.NAV_BAR_CLOSED_WIDTH,
  },
  content: {
    height: '100%',
  },
}

const Main = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const { user } = useContext(AuthContext)
  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <div
      style={{
        paddingTop: constants.TOP_BAR_HEIGHT,
        height: "100%",
        paddingLeft: !isDesktop ? "0px" : openSidebar ? constants.NAV_BAR_OPENED_WIDTH : constants.NAV_BAR_CLOSED_WIDTH
      }}
    >
      <Topbar onSidebarOpen={toggleSidebar} />
      <Sidebar
        user={user}
        onSidebarOpen={toggleSidebar}
        onSidebarClose={toggleSidebar}
        open={openSidebar}
        isDesktop={isDesktop}
      />
      <main style={styles.content}>
        <Outlet />
      </main>
    </div>
  );
};

export default Main;
