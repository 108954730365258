import {
  useClient,
  Action,
  convertActionToBase64,
  QueryResponse,
} from 'react-fetching-library';
import { timer, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { useEffect, useState, useCallback } from 'react';

export type SubscriptionOptions = {
  period?: number;
  retryCount?: number;
};

function createPollingObservable<T = any>(
  promiseCreator: () => Promise<QueryResponse<T>>,
  { period = 1000, retryCount = 10 }: SubscriptionOptions = {}
) {
  return timer(0, period).pipe(
    concatMap(() => from(promiseCreator())),
    retryBackoff({
      initialInterval: 300,
      maxRetries: retryCount,
      resetOnSuccess: true,
    })
  );
}

export default function useSubscription<T = any, R = any>(
  action: Action<T, R>,
  { period, retryCount }: SubscriptionOptions = {}
): {
  loading: boolean;
  response?: QueryResponse<T>;
  error?: any;
} {
  const { query } = useClient();

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<QueryResponse<T>>();
  const [error, setError] = useState(false);

  const promiseCreator = useCallback(
    () => query(action, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convertActionToBase64(action), query]
  );

  useEffect(() => {
    const subscription = createPollingObservable<T>(promiseCreator, {
      period,
      retryCount,
    }).subscribe({
      next: (response) => {
        setError(!!response.error);
        setResponse(response);
        setLoading(false);
      },
    });
    return () => subscription.unsubscribe();
  }, [promiseCreator, period, retryCount]);

  if (response?.errorObject) {
    throw response.errorObject
  }

  return {
    loading,
    response,
    error,
  };
}
