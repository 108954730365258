import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const styles = {
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '220px',
    width: 560,
  },
} as const;

export interface ZeroStateViewProps {
  header: string
}

const ZeroStateView: React.FunctionComponent<
  ZeroStateViewProps
> = ({ header }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h4">
          {header}
        </Typography>
        <Box textAlign="center">
          <img
            alt="Add user"
            style={styles.image}
            src="/images/undraw_add_files_re_v09g.svg"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default ZeroStateView;