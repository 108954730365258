import React from 'react';
import { FailureFilters, FailureFilterOptions } from './types';
import { Box } from '@mui/material';
import { FiltersPopover } from '@factorise/components';
import FilterFields from './widgets/Filters';

const styles = {
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
  },
};

export interface ToolbarProps {
  onFilter?: (filterValue: FailureFilters | '') => void;
  currentFilters: FailureFilters;
  filterOptions: FailureFilterOptions;
}

const Toolbar: React.FunctionComponent<ToolbarProps> = ({
  onFilter,
  filterOptions,
  currentFilters: appliedFilters,
}) => {
  const onClear = () => {
    onFilter?.('');
  };

  const onApply = (values: any) => {
    onFilter?.(values);
  };

  return (
    <Box sx={styles.row}>
      <FiltersPopover
        onApply={onApply}
        onClear={onClear}
        appliedFilters={appliedFilters}
        renderFilters={(onChange, currentFilters) => (
          <FilterFields
            currentFilters={currentFilters}
            filterOptions={filterOptions}
            onChange={onChange}
          />
        )}
      />
    </Box>
  );
};

export default Toolbar;
