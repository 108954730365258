import React, { useState } from 'react';
import { TableToolbar } from '@factorise/components';
import AddWorkCenter from "./AddWorkCenter"
import { Box } from '@mui/material';
import RemoveWorkCenter from './RemoveWorkCenter'

export interface EquipmentStatusesProps {
  onEquipmentAdd?: () => void;
  pagination: {
    page: number;
    onPageChange: (page: number) => void;
    onLimitChange: (page: number) => void;
    rowsTotal: number;
    rowsPerPage: number;
  };
  selectedEquipmentIds?: number[]
}

const EquipmentStatuses: React.FC<EquipmentStatusesProps> = ({ onEquipmentAdd, pagination, selectedEquipmentIds }) => {
  const [addWorkCenterModalOpened, setAddWorkCenterModalOpened] = useState(false)
  const [deleteWorkCenterModalOpened, setDeleteWorkCenterModalOpened] = useState(false)
  const { rowsTotal, page, rowsPerPage, onPageChange, onLimitChange } = pagination
  const singleEquipmentSelected = !selectedEquipmentIds || selectedEquipmentIds?.length !== 1
  const multipleEquipmentSelected = !selectedEquipmentIds || selectedEquipmentIds?.length < 1
  const [mode, setMode] = useState<"add" | "edit">('add')

  const onEditButtonClick = () => {
    setMode('edit')
    setAddWorkCenterModalOpened(true)
  }

  const onAddButtonClick = () => {
    setMode("add")
    setAddWorkCenterModalOpened(true)
  }

  const onDeleteButtonClick = () => {
    setDeleteWorkCenterModalOpened(true)
  }

  return (
    <Box>
      <RemoveWorkCenter
        open={deleteWorkCenterModalOpened}
        onReject={() => setDeleteWorkCenterModalOpened(false)}
        onConfirm={() => {
          setDeleteWorkCenterModalOpened(false)
          onEquipmentAdd?.()
        }}
        id={selectedEquipmentIds && selectedEquipmentIds[0]}
      />
      <TableToolbar
        onEditButtonClick={() => onEditButtonClick()}
        onDeleteButtonClick={() => onDeleteButtonClick()}
        editButtonDisabled={singleEquipmentSelected}
        deleteButtonDisabled={multipleEquipmentSelected}
        onAddButtonClick={() => onAddButtonClick()}
        pagination={
          {
            totalCount: rowsTotal,
            currentPage: page,
            pageSize: rowsPerPage,
            onPageChange,
            onLimitChange,
          }
        }
      />
      {addWorkCenterModalOpened &&
        <AddWorkCenter
          mode={mode}
          onEquipmentAdd={onEquipmentAdd}
          equipmentId={selectedEquipmentIds && selectedEquipmentIds[0]}
          onClose={() => setAddWorkCenterModalOpened(false)} />
      }
    </Box>
  );
};

export default EquipmentStatuses;
