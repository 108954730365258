import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    padding: 2,
    height: '150px',
  },
  text: {
    padding: 2,
  },
} as const;

export interface EmptyNotificationsProps {}

const EmptyNotifications: React.FC<EmptyNotificationsProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <img
        style={styles.root}
        alt="emptyNotifications"
        src="/images/undraw_happy_announcement_ac67.svg"
      />
      <Typography variant="h6" sx={styles.text}>
        {t('layouts.topbar.notificationsRead')}
      </Typography>
    </>
  );
};

export default EmptyNotifications;
