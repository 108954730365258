import React from 'react';
import { List, ListItem } from '@mui/material';
import NavItem from './NavItem';
import constants from './../../constants';

type Page = {
  title: string;
  href: string;
  icon: React.ReactNode;
};

type SidebarNavProps = {
  compactSize: boolean;
  pages: Page[];
  onSidebarClose?: () => void;
};

const SidebarNav = ({
  pages,
  compactSize,
  onSidebarClose,
}: SidebarNavProps) => {
  const pagesList = pages.map((page) => {
    const { title, href, icon } = page;
    return (
      <ListItem
        sx={{ width: compactSize ? constants.NAV_BAR_CLOSED_WIDTH : constants.NAV_BAR_OPENED_WIDTH, display: 'flex', py: "0px", justifyContent: 'center' }}
        disableGutters
        key={page.title}
      >
        <NavItem
          title={title}
          href={href}
          icon={icon}
          compactSize={compactSize}
          onClick={onSidebarClose}
        />
      </ListItem>
    );
  });

  return <List>{pagesList}</List>;
};

export default SidebarNav;
