import { Action } from "react-fetching-library";

interface AddFailureReasonAction {
  name: string;
  typeId: number;
  equipmentId: number
}

interface AddFailure {
  equipmentId: number;
  reasonId: number,
  priorityId: number,
  comment: string,
}

export interface FailureFilters {
  equipmentId?: number;
  reasonId?: number;
  userId?: number;
  priorityId?: number;
  statusId?: number | number[];
  parentEquipmentId: number | number[];
}

export interface GetFailureStatusesAction {
  statusTags?: string[];
}

export const getReasonType = (): Action => ({
  method: 'GET',
  endpoint: '/api/failureReasonTypes',
  config: {
    emitErrorForStatuses: [401],
  },
});

export const addFailureReason = ({ name, typeId, equipmentId }: AddFailureReasonAction): Action => ({
  method: 'POST',
  endpoint: '/api/failureReasons',
  body: [{ name, typeId, equipmentId }],
  config: {
    emitErrorForStatuses: [401],
  },
});

export const getFailurePriorities = (): Action => ({
  method: 'GET',
  endpoint: '/api/failurePriorities',
  config: {
    emitErrorForStatuses: [401],
  },
});

export const getFailureStatuses = (props?: GetFailureStatusesAction): Action => {
  let endpoint = ["/api/failureStatuses"]
  props && props.statusTags && endpoint.push(new URLSearchParams({
    statusTags: props.statusTags.join(',')
  }).toString())

  return {
    method: 'GET',
    endpoint: endpoint.join("?"),
    config: {
      emitErrorForStatuses: [401],
    },
  }
}

export const getFailureReasons = ({ equipmentId }: { equipmentId?: number }): Action => {
  let endpoint = ["/api/failureReasons"]

  equipmentId && endpoint.push(new URLSearchParams({
    equipmentId: equipmentId.toString()
  }).toString())

  return {
    method: 'GET',
    endpoint: endpoint.join("?"),
    config: {
      emitErrorForStatuses: [401],
    },
  };
}

export const addFailure = ({ equipmentId, reasonId, priorityId, comment }: AddFailure): Action => {
  return {
    method: 'POST',
    endpoint: '/api/failures',
    config: {
      emitErrorForStatuses: [401],
    },
    body: {
      equipmentId,
      reasonId,
      priorityId,
      comment,
    }
  };
}

export const getFailures = (props: {
  page?: number;
  rowsPerPage?: number;
  filters?: FailureFilters;
}): Action => {
  const { page, filters, rowsPerPage } = props;
  const endpoint = '/api/failures';
  const method = 'GET';
  let queryParams: string[] = [];

  if (filters) {
    const { equipmentId, reasonId, userId, priorityId, statusId, parentEquipmentId } = filters;
    equipmentId && queryParams.push(`equipmentId=${equipmentId}`);
    reasonId && queryParams.push(`reasonId=${reasonId}`);
    userId && queryParams.push(`userId=${userId}`);
    priorityId && queryParams.push(`priorityId=${priorityId}`);


    if (Array.isArray(parentEquipmentId)) {
      queryParams.push(`parentEquipmentId=${parentEquipmentId.map((id) => id).join(',')}`);
    } else {
      parentEquipmentId && queryParams.push(`parentEquipmentId=${parentEquipmentId}`);
    }

    if (Array.isArray(statusId)) {
      queryParams.push(`statusId=${statusId.map((id) => id).join(',')}`);
    } else {
      statusId && queryParams.push(`statusId=${statusId}`);
    }
  }

  if (page && rowsPerPage) {
    queryParams.push(`limit=${rowsPerPage}`);
    queryParams.push(`offset=${(page - 1) * rowsPerPage}`);
  }


  const action: Action = {
    method,
    endpoint: endpoint + '?' + queryParams.join('&'),
    config: {
      emitErrorForStatuses: [401],
    },
  };
  return action;
};

export const getFailureActions = ({ id }: { id: number }): Action => {
  return {
    method: 'GET',
    endpoint: `/api/failures/${id}/actions`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
}

export const getFailureActionReasons = (): Action => ({
  method: 'GET',
  endpoint: '/api/failureActionReasons',
  config: {
    emitErrorForStatuses: [401],
  },
});

export const getFailure = ({ id }: { id: number }): Action => {
  return {
    method: 'GET',
    endpoint: `/api/failures/${id}`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
}

//FIXME: needs to be used
// export const addFailureAction = ({ equipmentId, reasonId, priorityId, comment }: AddFailure): Action => {
//   return {
//     method: 'POST',
//     endpoint:`/api/failures/${failure?.id}/actions`,
//     config: {
//       emitErrorForStatuses: [401],
//     },
//     body: {
//       equipmentId,
//       reasonId,
//       priorityId,
//       comment,
//     }
//   };
// }