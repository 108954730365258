import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

interface RemoveWorkCenterProps {
  open: boolean
  onConfirm: () => void
  onReject: () => void
}

const RemoveWorkCenter: React.FunctionComponent<RemoveWorkCenterProps> = ({ open, onConfirm, onReject }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("shopfloor.equipmentStatuses.removeWorkCenterTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("shopfloor.equipmentStatuses.removeWorkCenterContent")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>{t("general.buttons.cancel")}</Button>
          <Button variant='contained' onClick={onConfirm} autoFocus>
            {t("general.buttons.remove")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RemoveWorkCenter;