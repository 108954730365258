import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import useClipboard from 'react-use-clipboard';
import { useSnackbar } from 'notistack';

export interface RemoveConfirmationDialogProps {
  onClose: () => void;
  password: string;
}

const RemoveConfirmationDialog: React.FC<RemoveConfirmationDialogProps> = ({
  onClose,
  password,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const title = t('reports.users.tempPasswordTitle');
  const [isCopied, setCopied] = useClipboard(password, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar(t('reports.users.passwordCopied'), {
        variant: 'info',
        preventDuplicate: true,
      });
      onClose();
    }
  });

  const footer = (
    <>
      <Button onClick={onClose} color="secondary">
        {t('general.buttons.close')}
      </Button>
      <Button onClick={setCopied} color="primary" variant="contained" autoFocus>
        {t('general.buttons.copy')}
      </Button>
    </>
  );

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {password}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
};

export default RemoveConfirmationDialog;
