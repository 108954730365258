import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export interface LoginViewPageProps {
  onSubmit: (
    user: { login: string, password: string, organization: string },
    onFormReset: () => void
  ) => void;
}

const LoginViewPage: React.FC<LoginViewPageProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const initialFormValues = {
    login: '',
    password: '',
    organization: ''
  };

  const validationSchema = Yup.object().shape({
    login: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    password: Yup.string()
      .min(8, t('auth.login.passwordMinLength'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
    organization: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
  });

  const onFormSubmit = (values: any, helpers: any) => {
    const { setSubmitting } = helpers;
    const { password, login, organization } = values;
    const user = { password, login, organization };

    const onFormReset = () => {
      setSubmitting(false);
    };

    onSubmit(user, onFormReset);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="on">
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {t('auth.login.headerPrimary')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.organization && errors.organization)}
                  fullWidth
                  helperText={touched.organization && errors.organization}
                  label={t('auth.login.organizationPlaceholder')}
                  margin="normal"
                  name="organization"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="organization"
                  value={values.organization}
                  variant="outlined"
                  autoComplete="current-organization"
                />
                <TextField
                  error={Boolean(touched.login && errors.login)}
                  fullWidth
                  helperText={touched.login && errors.login}
                  label={t('auth.login.loginPlaceholder')}
                  margin="normal"
                  name="login"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.login}
                  variant="outlined"
                  autoComplete="on"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('auth.login.passwordPlaceholder')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  autoComplete="current-password"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('auth.login.loginButtonText')}
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  {t('auth.login.signUpQuestion') + ' '}
                  <Link component={RouterLink} to="/register" variant="h6">
                    {t('auth.login.signUpLinkText')}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Box>


  );
};

export default LoginViewPage;
