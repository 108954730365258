import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TableContainer,
  Typography,
  Box,
  Checkbox
} from '@mui/material';
import { Equipment } from '@factorise/api/types';
import { useTranslation } from 'react-i18next';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WarningIcon from '@mui/icons-material/Warning';
import { Failure } from '@factorise/api/types';
import { Button } from '@mui/material';
import getCssContrastColoring from 'utils/getCssContrastColoring';
import LocationOnIcon from '@mui/icons-material/LocationOn';

type EquipmentStatusesTableProps = {
  equipment: Equipment[];
  onActionClick: (equipmentId: number) => void;
  failuresList: Failure[];
  selection: {
    selection: number[];
    onRecordSelect: (id: number) => void;
    onAllRecordsSelect: () => void;
  };
};

const styles = {
  failuresRoot: {
    display: 'flex',
  },
  failuresElement: {
    marginRight: '15px',
    marginTop: '5px',
  },
};

const EquipmentStatusesTable: React.FunctionComponent<
  EquipmentStatusesTableProps
> = ({ equipment, onActionClick, failuresList, selection }) => {
  const { t } = useTranslation();
  const {
    selection: currentSelection,
    onRecordSelect,
    onAllRecordsSelect
  } = selection;

  const selectedRowsNumber = selection.selection.length;
  const displayedRowsNumber = equipment.length;

  const headerCheckbox = [
    <TableCell key="checkbox" padding="checkbox">
      <Checkbox
        onChange={() => onAllRecordsSelect()}
        indeterminate={
          selectedRowsNumber > 0 && selectedRowsNumber < displayedRowsNumber
        }
        checked={
          displayedRowsNumber > 0 &&
          selectedRowsNumber === displayedRowsNumber
        }
      />
    </TableCell>,
  ];

  const getStatusTEMP = (equipmentId: number) => {
    const hasHighPriority = failuresList?.find(
      (failure) =>
        failure?.equipment?.parentId === equipmentId &&
        failure.priority.tag === 'HIGH'
    );

    return !hasHighPriority ? (
      <Chip
        style={getCssContrastColoring('#3f51b5')}
        label={'Praca'}
        size="small"
      />
    ) : (
      <Chip
        style={getCssContrastColoring('#BDBDBD')}
        label={'Przestój'}
        size="small"
      />
    );
  };

  const getFailuresCell = (equipmentId: number) => {
    return failuresList
      ?.filter((failure) => failure?.equipment?.parentId === equipmentId)
      .map((failure) => (
        <Box sx={styles.failuresRoot} key={failure.id}>
          <Chip
            sx={styles.failuresElement}
            style={getCssContrastColoring(failure.status.color)}
            label={failure.status.name}
            size="small"
          />

          <Box sx={{ ...styles.failuresElement, display: 'flex' }}>
            <WarningIcon sx={{ color: failure.priority.color }} />
            <Typography> {failure.reason.name}</Typography>
          </Box>

          <Box sx={{ ...styles.failuresElement, display: 'flex' }}>
            <LocationOnIcon />
            <Typography>{failure.equipment.name}</Typography>
          </Box>
        </Box>
      ));
  };

  return (
    <Card>
      <TableContainer>
        <Table size='small'>
          <TableHead sx={{ minWidth: '80px' }}>
            <TableRow>
              {headerCheckbox}
              <TableCell>
                {t('shopfloor.equipmentStatuses.workCenter')}
              </TableCell>
              <TableCell>{t('shopfloor.equipmentStatuses.status')}</TableCell>
              <TableCell>
                {t('shopfloor.equipmentStatuses.production')}
              </TableCell>
              <TableCell>{t('shopfloor.equipmentStatuses.order')}</TableCell>
              <TableCell>{t('shopfloor.equipmentStatuses.failure')}</TableCell>
              <TableCell>{t('shopfloor.equipmentStatuses.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipment.map(({ id, name }) => (
              <TableRow onClick={() => onRecordSelect(id || 0)} hover key={id}>
                <TableCell padding="checkbox">
                  <Checkbox checked={currentSelection.includes(id || 0)} />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{getStatusTEMP(id)}</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell width="50%">{getFailuresCell(id)}</TableCell>
                <TableCell width="20px">
                  <Button onClick={() => onActionClick?.(id)}>
                    <EngineeringIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default EquipmentStatusesTable;
