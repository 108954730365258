import { useEffect } from 'react';
import {
  useQuery as useFetchingQuery,
  Action,
  QueryResponse,
} from 'react-fetching-library';

interface UseQuery<T> {
  loading: boolean;
  response?: QueryResponse<T>;
  error?: any;
  query: () => Promise<QueryResponse<T>>;
  reset: () => void;
}

const useQuery = <T>(
  action: Action,
  statusHandler?: (status: number) => void
): UseQuery<T> => {
  const { status, error, payload, loading, headers, query, reset } =
    useFetchingQuery<T>({ ...action });

  useEffect(
    () => {
      if (statusHandler && status && status !== 200) {
        statusHandler?.(status)
      }
    }, [status, statusHandler]
  )


  const response: QueryResponse<T> = {
    status,
    error,
    payload,
    headers,
  };

  return { loading, error, response, query, reset };
};
export default useQuery;
