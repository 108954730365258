import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { useTranslation } from 'react-i18next';

export interface AddProductProps {
  onClick: () => void;
  disabled: boolean
}

const AddProduct: React.FC<AddProductProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('general.buttons.remove') || ''}>
      <span>
        <IconButton disabled={disabled} onClick={onClick} size="large">
          <DeleteForeverSharpIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default AddProduct;
