import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

export interface BackdropLoaderProps {}

const styles = {
  backdrop: {
    zIndex: 'zIndex.drawer + 1',
    color: '#fff',
    position: 'absolute',
  },
} as const;

const BackdropLoader: React.FunctionComponent<BackdropLoaderProps> = () => {
  return (
    <Backdrop open sx={styles.backdrop}>
      <CircularProgress />
    </Backdrop>
  );
};

export default BackdropLoader;
