import React from 'react';
import FilterFieldsView from './FilterFields';
import { FailureFilters, FailureFilterOptions } from '../../types';

export interface FilterFinishedGoodsProps {
  filterOptions: FailureFilterOptions;
  currentFilters: Partial<FailureFilters>;
  onChange: (name: string, value: any) => void;
}

const FilterFields: React.FunctionComponent<FilterFinishedGoodsProps> = ({
  currentFilters,
  filterOptions,
  onChange,
}) => {
  const { equipment, priorities, reasons, statuses, users } = filterOptions;

  const equipmentList = equipment?.map((equipment) => ({
    value: equipment.id,
    label: equipment.name,
  }));

  const prioritiesList = priorities?.map((priority) => ({
    value: priority.id,
    label: priority.name,
  }));

  const reasonsList = reasons?.map((reason) => ({
    value: reason.id,
    label: reason.name,
  }));

  const statusesList = statuses?.map((status) => ({
    value: status.id || 0,
    label: status.name,
  }));

  const usersList = users?.map((user) => ({
    value: user.id || 0,
    label: `${user.firstName} ${user.lastName}  - ${user.login} `,
  }));

  return (
    <>
      <FilterFieldsView
        value={currentFilters}
        onChange={onChange}
        equipment={equipmentList}
        reasons={reasonsList}
        users={usersList}
        priorities={prioritiesList}
        statuses={statusesList}
      />
    </>
  );
};

export default FilterFields;
