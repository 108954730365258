import React from 'react';
import { IconButton, Tooltip, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';

export interface NotificationsButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  badgeContent: number;
}

const NotificationsButton: React.FC<NotificationsButtonProps> = ({
  onClick,
  badgeContent,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('layouts.topbar.notifications') || ''}>
      <IconButton onClick={onClick} size="large">
        <Badge badgeContent={badgeContent} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default NotificationsButton;
