import React, { useState, createContext, useEffect } from 'react';
import { useQuery, UserActions } from '@factorise/api';
import { User } from '@factorise/api/types';
import AppLoading from 'views/info/AppLoading';
import { AuthActions, useMutation } from '@factorise/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { unsubscribeUser } from '@factorise/service-worker'

export interface AuthContextProps {
  user: User | null
  availableViews: string[],
  logIn: (login: string, password: string, organization: string) => Promise<void>;
  logOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  availableViews: [],
  logIn: () => Promise.resolve(),
  logOut: () => Promise.resolve(),
});

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [user, setUser] = useState<AuthContextProps['user']>(null)
  const [availableViews, setAvailableViews] = useState<AuthContextProps['availableViews']>([])
  const [loading, setLoading] = useState(true)
  const { error, loading: userInitLoading, response, query } = useQuery<UserActions.MeApiResponse>(UserActions.me());
  const { mutate: loginMutation } = useMutation(AuthActions.loginAction);
  const { mutate: logoutMutation } = useMutation(AuthActions.logOutAction);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!error && !userInitLoading && response?.payload?.data) {
      setUser(response.payload.data.user);
      setAvailableViews(response.payload.data.viewsAccesses);
    }
    setLoading(userInitLoading)
  }, [error, userInitLoading, response?.payload?.data, setUser, setAvailableViews]);


  const showServerError = () => {
    enqueueSnackbar(t('auth.login.authServerConnectionError'), {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const showCredentialsError = () => {
    enqueueSnackbar(t('auth.login.wrongCredentials'), {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const syncContextWithDataBase = () => {
    query().then(
      (response) => {
        if (response.payload?.data) {
          setUser(response.payload?.data.user);
          setAvailableViews(response.payload.data.viewsAccesses);
        } else {
          setUser(null)
          setAvailableViews([]);
        }
      }
    )
  };

  const logIn = async (login: string, password: string, organization: string) => {
    const { status } = await loginMutation({ login, password, organization });
    switch (status) {
      case 200:
        syncContextWithDataBase();
        break;
      case 401:
        showCredentialsError();
        break;
      default:
        showServerError();
        break;
    }
  }

  const logOut = async () => {
    const { status } = await logoutMutation({});
    setUser(null)
    setAvailableViews([]);
    switch (status) {
      case 200:
        syncContextWithDataBase();
        closeSnackbar()
        unsubscribeUser();
        break;
      case 500:
        showServerError();
        break;
    }
  }

  return (
    <AuthContext.Provider value={{ user, availableViews, logIn, logOut }}>
      {loading ? <AppLoading /> : props.children}
    </AuthContext.Provider>
  )
}