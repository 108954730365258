import React from 'react';
import { Card, Grid, CardContent, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import DescriptionIcon from '@mui/icons-material/Description';
import BuildIcon from '@mui/icons-material/Build';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CommentIcon from '@mui/icons-material/Comment';
import { useTranslation } from 'react-i18next';
import { Failure } from '@factorise/api/types';
import moment from 'moment';

export interface FailureInfoProps {
  failure?: Failure;
}

const styles = {
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  statsIcon: {
    mr: 1,
  },
  pathRoot: {
    listStyle: 'none',
  },
  pathElement: {
    display: 'inline',
    '&:after': { content: '" / "' },
  },
} as const;

const FailureInfo: React.FunctionComponent<FailureInfoProps> = ({
  failure,
}) => {
  const { t } = useTranslation();

  const getLocationPath = (location?: string[]) => {
    const lastElement = location?.pop();

    return (
      <ul style={styles.pathRoot}>
        {location?.map((location) => (
          <li style={styles.pathElement} key={location}>
            <Typography color="textSecondary" display="inline" variant="body2">
              {location}
            </Typography>
          </li>
        ))}
        <strong>
          <Typography color="textSecondary" display="inline" variant="body2">
            {lastElement}
          </Typography>
        </strong>
      </ul>
    );
  };

  const comment = failure?.comment;
  const userString = failure?.user
    ? `${failure?.user?.firstName} ${failure?.user?.lastName}`
    : '';

  return (
    <Card>
      <CardContent>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid sx={styles.statsItem} item md={6} xs={6}>
              <AccessTimeIcon sx={styles.statsIcon} color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                {t('modals.failureResolving.updated')}{' '}
                {moment(failure?.createdAt).fromNow()}
              </Typography>
            </Grid>
            <Grid sx={styles.statsItem} item md={6} xs={6}>
              <PersonIcon sx={styles.statsIcon} color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                {t('modals.failureResolving.reportedBy')} {userString}
              </Typography>
            </Grid>
            <Grid sx={styles.statsItem} item md={12} xs={12}>
              <PlaceIcon sx={styles.statsIcon} color="action" />
              {getLocationPath([failure?.equipment?.name || ''])}
            </Grid>
            <Grid sx={styles.statsItem} item md={12} xs={12}>
              <DescriptionIcon sx={styles.statsIcon} color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                {failure?.reason?.name}
              </Typography>
            </Grid>
            <Grid sx={styles.statsItem} item md={6} xs={6}>
              <BuildIcon sx={styles.statsIcon} color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                {failure?.reason?.type?.name}
              </Typography>
            </Grid>
            <Grid sx={styles.statsItem} item md={6} xs={6}>
              <PriorityHighIcon sx={styles.statsIcon} color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                <strong> {failure?.priority?.name} </strong>
              </Typography>
            </Grid>
            {comment && (
              <Grid sx={styles.statsItem} item md={12} xs={12}>
                <CommentIcon sx={styles.statsIcon} color="action" />
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                >
                  <strong> {comment} </strong>
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default FailureInfo;
