import React, { useState } from 'react';

import NotificationsPopover from './NotificationsPopover';
import NotificationsStream, { Notification } from './NotificationsStream';
import NotificationsButton from './NotificationsButton';

export interface NotificationProps {
  notifications: Notification[];
}

const UserNotifications: React.FunctionComponent<NotificationProps> = ({
  notifications,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NotificationsButton
        onClick={openPopover}
        badgeContent={notifications.length}
      />
      <NotificationsPopover anchorEl={anchorEl} handleClose={closePopover}>
        <NotificationsStream notifications={notifications} />
      </NotificationsPopover>
    </>
  );
};

export default UserNotifications;
