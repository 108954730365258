import React, { useState } from 'react';
import FailureReportingDialog from './FailureReportingDialog';
import { UserActions, EquipmentActions, useQuery, FailureActions } from '@factorise/api';
import { Equipment, Failure, User, FailurePriority, FailureReasons } from '@factorise/api/types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-fetching-library';
import { set as _set } from 'lodash';

export interface FailureReportingProps {
  open: boolean;
  onClose: () => void;
  equipmentId: number;
}

const FailureReporting: React.FC<FailureReportingProps> = ({
  open,
  onClose,
  equipmentId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState<Partial<Failure>>();
  const [reasons, setReasons] = useState<FailureReasons[]>()

  const userQuery = useQuery<{ data: { user: User } }>(UserActions.me());
  const equipmentQuery = useQuery<Equipment[]>(EquipmentActions.getEquipmentChildren({ id: equipmentId }));
  const prioritiesQuery = useQuery<FailurePriority[]>(FailureActions.getFailurePriorities());
  const reasonsQuery = useQuery<FailureReasons[]>(FailureActions.getFailureReasons({ equipmentId: formValues?.equipment?.id || 0 }));
  const pathQuery = useQuery<{ data: Equipment[] }>(EquipmentActions.getEquipmentPath({ id: equipmentId }));

  if (
    userQuery.error ||
    equipmentQuery.error ||
    prioritiesQuery.error ||
    pathQuery.error ||
    reasonsQuery.error
  ) {
    enqueueSnackbar(t('modals.failureResolving.fetchingDataError'), {
      variant: 'error',
      preventDuplicate: true,
    });
  }

  const {
    mutate,
    reset,
    loading: operationInProgress,
  } = useMutation(FailureActions.addFailure);

  enum KEYS {
    EQUIPMENT = 'equipment.id',
    REASON = 'reason.id',
    PRIORITY = 'priority.id',
    COMMENT = 'comment',
  }

  const onChange = (key: string, value: any) => {
    setFormValues((prev: Partial<Failure> | undefined) => {
      const newState = { ...prev } || {};
      if (key === 'EQUIPMENT') {
        _set(newState, KEYS.EQUIPMENT, value);
        _set(newState, KEYS.REASON, null);
      } else if (key === 'REASON') {
        _set(newState, KEYS.REASON, value);
      } else if (key === 'PRIORITY') {
        _set(newState, KEYS.PRIORITY, value);
      } else if (key === 'COMMENT') {
        _set(newState, KEYS.COMMENT, value);
      }
      return newState;
    });
  };

  const onReport = async () => {
    reset();
    if (!formValues) return;
    const { error } = await mutate({
      equipmentId: formValues.equipment?.id || 0,
      reasonId: formValues.reason?.id || 0,
      priorityId: formValues.priority?.id || 0,
      comment: formValues.comment || ""
    });
    if (error) {
      enqueueSnackbar(t('general.queryErrors.actionFailed'), {
        variant: 'error',
        disableWindowBlurListener: true,
      });
      return;
    }
    enqueueSnackbar(t('modals.failureReporting.successMessage'), {
      variant: 'success',
      disableWindowBlurListener: true,
    });
    setFormValues({});
    onClose();
  };

  const onFailureReasonAdd = (id: number) => {
    reasonsQuery.query().then(
      (response) => {
        setReasons(response?.payload)
      }
    )
    onChange("REASON", id)
  }

  const pathData = pathQuery.response?.payload;
  const userData = userQuery.response?.payload;
  const equipmentData = equipmentQuery.response?.payload;
  const prioritiesData = prioritiesQuery.response?.payload;
  const reasonsData = reasonsQuery.response?.payload
  if (reasonsData && reasonsData !== reasons) {
    setReasons(reasonsData)
  }

  const path =
    pathData?.data.map((equipment: Equipment) => equipment.name) || [];

  const loading =
    pathQuery.loading ||
    userQuery.loading ||
    equipmentQuery.loading ||
    prioritiesQuery.loading ||
    operationInProgress;

  return open ? <FailureReportingDialog
    fetchEquipment={equipmentQuery.query}
    loading={loading}
    user={userData?.data?.user}
    open={open}
    onClose={onClose}
    equipment={
      equipmentData?.map((equipment) => ({
        value: equipment.id,
        label: equipment.name,
      })) || []
    }
    failurePriorities={
      prioritiesData?.map((priority) => ({
        value: priority.id,
        label: priority.name,
      })) || []
    }
    value={formValues}
    onChange={onChange}
    onReport={onReport}
    locationPath={path}
    parentEquipmentId={equipmentId}
    reasons={reasons || []}
    reasonsLoading={reasonsQuery.loading}
    onFailureReasonAdd={onFailureReasonAdd}
  /> : null

};

export default FailureReporting;
