import React from 'react';
import FailureResolvingDialog from './FailureResolvingDialog';
import { EquipmentActions, FailureActions, UserActions, useQuery } from '@factorise/api';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Equipment,
  EquipmentPart,
  Failure,
  FailureActionReason,
  FailureStatus,
  User,
} from '@factorise/api/types';

export interface FailureResolvingProps {
  open: boolean;
  onClose: () => void;
  failureId: number;
  onActionPerformed?: () => void;
  onError?: () => void;
}

const FailureResolving: React.FunctionComponent<FailureResolvingProps> = ({
  open,
  onClose,
  failureId,
  onActionPerformed,
  onError,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const userQuery = useQuery<{
    data: { user: User; equipment: Equipment };
  }>(UserActions.me());
  const failureQuery = useQuery<{ data: Failure }>(FailureActions.getFailure({ id: failureId }));
  const partsQuery = useQuery<EquipmentPart[]>(EquipmentActions.getEquipmentParts({ id: failureQuery?.response?.payload?.data.equipment.id || 0 }));
  const reasonsQuery = useQuery<FailureActionReason[]>(FailureActions.getFailureActionReasons());
  const statusesQuery = useQuery<FailureStatus[]>(FailureActions.getFailureStatuses());

  const user = userQuery?.response?.payload?.data?.user;
  const failure = failureQuery?.response?.payload?.data;
  const parts = partsQuery?.response?.payload;
  const reasons = reasonsQuery?.response?.payload;
  const statuses = statusesQuery?.response?.payload;

  const error =
    userQuery.error ||
    failureQuery.error ||
    partsQuery.error ||
    reasonsQuery.error;

  const loading =
    userQuery.loading ||
    failureQuery.loading ||
    partsQuery.loading ||
    reasonsQuery.loading;

  const noData = !user || !failure || !parts || !reasons || !statuses;

  if (!loading && (error || noData)) {
    onError?.();
    enqueueSnackbar(t('modals.failureResolving.fetchingDataError'), {
      variant: 'error',
      preventDuplicate: true,
    });
    return null;
  }

  return (
    <FailureResolvingDialog
      loading={loading}
      error={true}
      user={user}
      failure={failure}
      parts={parts}
      reasons={reasons}
      statuses={statuses}
      open={open}
      onClose={onClose}
      onActionPerformed={onActionPerformed}
    />
  );
};

export default FailureResolving;
