import React from 'react';
import {
  Avatar, Box,
  // Button
} from '@mui/material';
// import { useTranslation } from 'react-i18next';

const styles = {
  avatar: {
    height: 100,
    width: 100,
  },
};

export interface ProfileProps {
  avatarText?: string;
  src?: string;
}

const Profile: React.FC<ProfileProps> = ({ avatarText, src }) => {
  // const { t } = useTranslation();
  return (
    <>
      <Box
        width="100%"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        {!avatarText ? (
          <Avatar sx={styles.avatar} src={src} />
        ) : (
          <Avatar sx={styles.avatar}>{avatarText}</Avatar>
        )}
      </Box>
      {/* FIXME: Enable avatar uploading */}
      {/* <Button disabled color="primary" fullWidth variant="text">
        {t('account.uploadPicture')}
      </Button> */}
    </>
  );
};

export default Profile;
