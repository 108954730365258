import React, { useContext } from 'react';
import SidebarComponent from './SidebarComponent';
import { User } from '@factorise/api/types';
import { AuthContext } from 'contexts/AuthContext'

interface SidebarProps {
  open: boolean;
  isDesktop: boolean;
  onSidebarClose: () => void;
  onSidebarOpen: () => void;
  user?: User | null;
}
const Sidebar: React.FC<SidebarProps> = ({
  open,
  isDesktop,
  onSidebarClose,
  onSidebarOpen,
  user,
}) => {
  const { availableViews } = useContext(AuthContext)
  return (
    <SidebarComponent
      user={user}
      open={open}
      isDesktop={isDesktop}
      onSidebarClose={onSidebarClose}
      onSidebarOpen={onSidebarOpen}
      availableViews={availableViews}
    />
  );
};

export default Sidebar;
