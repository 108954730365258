import React from 'react';
import { Box, Container } from '@mui/material';

const styles = {
  image: {
    marginTop: '50%',
    marginBottom: '50%',
    display: 'inline-block',
    maxWidth: 150,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
} as const;

const AppLoading = () => {
  return (
    <Box sx={styles.root}>
      <Container maxWidth="md">
        <Box textAlign="center">
          <img
            alt="Loading"
            style={styles.image}
            src="/images/logos/arrow512.svg"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AppLoading;
