import React from 'react';
import Topbar from './Topbar';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import constants from './../constants'

const styles = {
  root: {
    paddingTop: constants.TOP_BAR_HEIGHT,
    height: "100%",
  },
  content: {
    height: '100%',
  },
} as const;

const Minimal = () => {
  return (
    <Box sx={styles.root}>
      <Topbar />
      <main style={styles.content}>
        <Outlet />
      </main>
    </Box>
  );
};

export default Minimal;
