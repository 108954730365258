function luminance(r: number, g: number, b: number) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

const convertToRGB = (hexColor: string) => {
  var aRgbHex = hexColor.replace(/[!@#$%^&*]/g, '').match(/.{1,2}/g);
  var aRgb = [0, 0, 0];

  if (aRgbHex) {
    aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
    ];
  }
  return aRgb;
};

function isBrightContrasting(hexColor: string) {
  var rgb = convertToRGB(hexColor);
  var lum1 = luminance(rgb[0], rgb[1], rgb[2]);
  var lum2 = luminance(0, 0, 0);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05) <= 4;
}

const getCssContrastColoring = (statusColor: string) => {
  var style = { background: statusColor };
  if (isBrightContrasting(statusColor)) {
    Object.assign(style, { color: 'white' });
  }
  return style;
};

export default getCssContrastColoring;
