import React from 'react';
import { Avatar, Typography, Box } from '@mui/material';
import getInitials from 'utils/getInitials';
import { User } from '@factorise/api/types';

const styles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  } as const,
  avatar: {
    width: "60px",
    height: "60px",
  },
  smallAvatar: {
    width: "30px",
    height: "30px",
  },
  name: {
    marginTop: "0px",
  },
};

export type ProfileProps = {
  avatarOnly: boolean;
  user?: User | null;
};

const Profile: React.FC<ProfileProps> = ({ avatarOnly, user }) => {
  const avatarPath = user?.avatar;
  const userName = `${user?.firstName} ${user?.lastName}`;

  const avatar = (
    <Avatar
      alt="Person"
      sx={avatarOnly ? styles.smallAvatar : styles.avatar}
      src={avatarPath}
    >
      {user && !avatarPath && getInitials(userName)}
    </Avatar>
  );

  return (
    <Box sx={styles.root}>
      {avatar}
      {!avatarOnly && (
        <>
          <Typography color="textPrimary" variant="h5" marginTop="6px">
            {user && userName}
          </Typography>
          {
            user?.roles && user?.roles?.map((role) =>
              <Typography key={role.name} color="textSecondary" variant="body2" marginTop="6px">
                {role.name}
              </Typography>
            )
          }
        </>
      )}
    </Box>
  );
};

export default Profile;
