import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Page } from '@factorise/components';
import { useTranslation } from 'react-i18next';

const styles = {
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
} as const;

const NotFoundView = () => {
  const { t } = useTranslation();
  return (
    <Page title="404" permitted>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            {t('info.notFound.header')}
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            {t('info.notFound.body')}
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              style={styles.image}
              src="/images/undraw_page_not_found_su7k.svg"
            />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
