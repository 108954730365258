import React from 'react';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReportFrom from './ReportForm';
import ReportInfo from './ReportInfo';
import { useTranslation } from 'react-i18next';
import { ListBoxOption } from 'types';
import { Failure, User, FailureReasons } from '@factorise/api/types';
import { SimpleDialog } from '@factorise/components';

export interface FailureReportingDialogProps {
  loading: boolean;
  open: boolean;
  onClose: () => void;
  equipment: ListBoxOption[];
  locationPath: string[];
  failurePriorities: ListBoxOption[];
  value?: Partial<Failure>;
  onChange: (key: string, value: string | number | Date | boolean) => void;
  onReport: () => void;
  user?: User;
  parentEquipmentId: number;
  fetchEquipment: () => void
  reasons: FailureReasons[]
  reasonsLoading: boolean
  onFailureReasonAdd: (id: number) => void
}

const FailureReportingDialog: React.FunctionComponent<
  FailureReportingDialogProps
> = ({
  loading,
  open,
  onClose,
  equipment,
  locationPath,
  failurePriorities,
  value,
  onChange,
  onReport,
  user,
  parentEquipmentId,
  fetchEquipment,
  reasons,
  reasonsLoading,
  onFailureReasonAdd
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const reportButtonDisabled =
      !value?.equipment?.id ||
      !value?.reason?.id ||
      !value?.priority?.id ||
      loading;

    const actions = (
      <>
        <Button autoFocus onClick={onClose} color="secondary">
          {t('general.buttons.cancel')}
        </Button>
        <Button
          disabled={reportButtonDisabled}
          variant="contained"
          onClick={onReport}
          color="primary"
          autoFocus
        >
          {t('general.buttons.report')}
        </Button>
      </>
    );

    return (
      <SimpleDialog
        fullScreen={fullScreen}
        open={open}
        showLoader={loading}
        title={t('modals.failureReporting.title')}
        actions={actions}
      >
        <ReportInfo user={user} locationPath={locationPath} />
        <ReportFrom
          onFailureReasonAdd={onFailureReasonAdd}
          fetchEquipment={fetchEquipment}
          value={value}
          onChange={onChange}
          equipment={equipment}
          failurePriorities={failurePriorities}
          equipmentId={value?.equipment?.id}
          parentEquipmentId={parentEquipmentId}
          reasons={reasons}
          reasonsLoading={reasonsLoading}
        />
      </SimpleDialog>
    );
  };

export default FailureReportingDialog;
