import React, { useState, useContext } from 'react';
import AddButton from './AddButton';
import AddReason, { ReasonForm } from './AddReasonForm';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts/AuthContext';
import { useQuery } from '@factorise/api';
import { FailureReasonsType } from '@factorise/api/types';
import { FailureActions } from '@factorise/api'

export interface AddReasonContainerProps {
  onFailureReasonAdd?: (id: number) => void;
  disabled?: boolean
  equipmentId?: number
}

const AddReasonContainer: React.FunctionComponent<AddReasonContainerProps> = ({
  onFailureReasonAdd,
  disabled,
  equipmentId
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext)
  const [dialogOpened, setDialogOpened] = useState(false);
  const reasonTypesQuery = useQuery<FailureReasonsType[]>(FailureActions.getReasonType());
  const { mutate, reset } = useMutation(FailureActions.addFailureReason);

  const openDialog = () => { setDialogOpened(true) };
  const closeDialog = () => { setDialogOpened(false) };

  const submitForm = async (
    data: ReasonForm,
    resetForm: () => void
  ) => {
    if (!user?.organization.id || !equipmentId) return

    const { payload } = await mutate({
      name: data.name,
      typeId: data.typeId,
      equipmentId
    });

    resetForm();
    reset();

    enqueueSnackbar(t('modals.addingReason.added'), {
      variant: 'success',
      disableWindowBlurListener: true,
    });

    onFailureReasonAdd?.(payload.data[0].id);
    closeDialog();
  };

  return (
    <>
      <AddButton disabled={disabled} onClick={openDialog} />
      <AddReason
        failureTypes={reasonTypesQuery?.response?.payload?.map(
          (type) => ({ value: type.id, label: type.name })
        )}
        onClose={closeDialog}
        open={dialogOpened}
        onSubmit={submitForm}
      />
    </>
  );
};

export default AddReasonContainer;
