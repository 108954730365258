import React from 'react';
import { Alert, AlertTitle, Box, IconButton, Stack, } from '@mui/material';
import getFormattedDate from 'utils/getFormattedDate';
import EmptyNotifications from './EmptyNotifications';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  clearUserNotifications,
} from 'utils/UserNotifications';

const styles = {
  notification: {
    width: "100%",
  },
  notificationContent: {
    display: 'flex',
    flexDirection: 'column',
  },
} as const;

export interface Notification {
  header: string;
  message: string;
  severity: 'error' | 'warning' | 'info';
  date: Date;
}

export interface NotificationsStreamProps {
  notifications: Notification[];
}

const NotificationsStream: React.FC<NotificationsStreamProps> = ({
  notifications,
}) => {
  const notificationElements = notifications.map(
    (notification: Notification) => {
      const formattedDate = getFormattedDate(notification.date);
      return (
        <Alert
          icon={false}
          severity={notification.severity}
          sx={styles.notification}
          key={notification.date + notification.message}
        >
          <AlertTitle>{notification.header}</AlertTitle>
          <Box sx={styles.notificationContent}>
            <b>{notification.message}</b>
            {formattedDate}
          </Box>
        </Alert>
      );
    }
  );

  return (
    notifications.length ?
      <Stack sx={{ width: "100%" }} alignItems='end'>
        {notificationElements}
        <Box>
          <IconButton onClick={() => clearUserNotifications()}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Stack>
      : <EmptyNotifications />
  );
};

export default NotificationsStream;
