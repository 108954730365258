import React from 'react';
import { IconButton, Tooltip, Badge } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';

const styles = {
  button: {
    mr: 1,
  },
};

export interface FilterButtonProps {
  badgeContent?: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddProduct: React.FC<FilterButtonProps> = ({ onClick, badgeContent }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('reports.finishedGoods.filters') || ''}>
      <IconButton sx={styles.button} onClick={onClick} size="large">
        <Badge badgeContent={badgeContent} color="secondary">
          <FilterListIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default AddProduct;
