import React from 'react';
import AddButton from './AddButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import { Pagination as PaginationComponent } from '@factorise/components';
import { Box } from '@mui/material';

const styles = {
  row: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
} as const;

export interface Pagination {
  totalCount: number,
  currentPage: number,
  pageSize: number,
  onPageChange: (page: number) => void,
  onLimitChange: (limit: number) => void
}

export interface TableToolbarProps {
  addButtonDisabled?: boolean,
  editButtonDisabled?: boolean,
  deleteButtonDisabled?: boolean,
  editButtonHidden?: boolean,
  deleteButtonHidden?: boolean,
  onAddButtonClick: () => void,
  onEditButtonClick?: () => void,
  onDeleteButtonClick?: () => void,
  pagination?: Pagination
}

const TableToolbar: React.FunctionComponent<TableToolbarProps> = ({
  addButtonDisabled,
  editButtonDisabled,
  deleteButtonDisabled,
  editButtonHidden,
  deleteButtonHidden,
  onAddButtonClick,
  onEditButtonClick = () => { },
  onDeleteButtonClick = () => { },
  pagination
}) => {
  return (
    <Box sx={styles.row}>
      <AddButton disabled={addButtonDisabled || false} onClick={onAddButtonClick} />
      {!editButtonHidden && <EditButton disabled={editButtonDisabled || false} onClick={onEditButtonClick} />}
      {!deleteButtonHidden && <DeleteButton disabled={deleteButtonDisabled || false} onClick={onDeleteButtonClick} />}
      <Box sx={styles.spacer} />
      {
        pagination && <PaginationComponent
          rowsTotal={pagination.totalCount}
          page={pagination.currentPage}
          rowsPerPage={pagination.pageSize}
          onPageChange={pagination.onPageChange}
          onLimitChange={pagination.onLimitChange}
        />
      }
    </Box>
  );
};

export default TableToolbar;
