import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import NoDataReceived from '../NoDataReceived';

const styles = {
  progress: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: "100%",
    height: "100%"
  },
};

export interface ComponentWithErrorAndLoadingProps {
  loading: boolean;
  error: boolean;
  children: React.ReactElement
}

const ComponentWithErrorAndLoading: React.FunctionComponent<
  ComponentWithErrorAndLoadingProps
> = ({ loading, error, children }) => {
  const getLoaderElement = () => (
    <Box sx={styles.progress}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box sx={styles.root}>
      {loading ? getLoaderElement() : error ? <NoDataReceived /> : children}
    </Box>
  );
};

export default ComponentWithErrorAndLoading;
